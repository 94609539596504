import React, {useEffect, useState} from "react";
import PasswordInputTemplate from "./PasswordInputTemplate";

export default function LoginPasswordInput({id, name, handleChange, placeHolder, value, setPasswordInputValid = null}) {
    const [passwordMasked, setPasswordMasked] = useState(true)
    const [borderColor, setBorderColor] = useState('lightgray')

    useEffect(() => {
        if (value.length === 0) {
            setBorderColor('lightgray')
        } else {
            setBorderColor('black')
        }
    }, [value])

    useEffect(() => {
        if (setPasswordInputValid === null) {
            return
        }

        if (borderColor === 'black') {
            setPasswordInputValid(true)
            return
        }

        setPasswordInputValid(false)
    }, [borderColor, setPasswordInputValid])

    return (
        <PasswordInputTemplate
            passwordMasked={passwordMasked}
            setPasswordMasked={setPasswordMasked}
            handleChange={handleChange}
            placeHolder={placeHolder}
            id={id}
            name={name}
            value={value}
            borderColor={borderColor}
        />
    )

}