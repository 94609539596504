import SliderNextArrow from "../Components/SliderNextArrow";
import SliderPrevArrow from "../Components/SliderPrevArrow";

export default function SliderForBanner() {
    return {
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: true,
        rows: 1,
        prevArrow: <SliderPrevArrow/>,
        nextArrow: <SliderNextArrow/>,
        mobileFirst: true,
    }
}
