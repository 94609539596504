import {Modal, ModalBody} from "react-bootstrap";
import LoadMorePages from "../LoadMorePages";
import MarkAllAsReadButton from "./MarkAllAsReadButton";
import UserNotification from "./UserNotification";

export default function NotificationsModal({show, onHide, paginatedUserNotifications, hasUnreadNotifications, hasNotifications}) {

    return (
        <Modal show={show} onHide={onHide} contentClassName={"rounded-modal-border"}>
            <Modal.Header closeButton className={"border-0"}>
                <Modal.Title>
                    通知
                </Modal.Title>

            </Modal.Header>
            <ModalBody className={"pt-4"}>
                {
                    paginatedUserNotifications.isSuccess &&
                    <div>
                        {
                            hasUnreadNotifications &&
                            <MarkAllAsReadButton paginatedUserNotifications={paginatedUserNotifications}/>
                        }
                        {
                            !hasNotifications
                                ? <div className={"fs-6"}>尚無通知。</div>
                                : <div>
                                    {
                                        paginatedUserNotifications.data.pages.map((page, index) => {
                                            return page.data.map((notification, index) => {
                                                return (
                                                    <UserNotification notification={notification} key={index}/>
                                                )
                                            })
                                        })
                                    }

                                    <LoadMorePages pagination={paginatedUserNotifications}
                                                   loadMoreText={'更多通知'}/>
                                </div>
                        }

                    </div>
                }
            </ModalBody>
        </Modal>
    )
}
