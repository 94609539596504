import {useMarkAllNotificationsAsRead} from "../../APIs/apis";
import {useQueryClient} from "@tanstack/react-query";
import {Spinner} from "react-bootstrap";

export default function MarkAllAsReadButton({paginatedUserNotifications}) {
    const markAllNotificationsAsReadMutation = useMarkAllNotificationsAsRead()
    const queryClient = useQueryClient()

    const handleClickMarkAllAsReadButton = async () => {
        await markAllNotificationsAsReadMutation.mutateAsync()
        queryClient.invalidateQueries('paginated-user-notifications')
    }
    return (
        <div className={"mb-4 d-flex justify-content-end"}>
            <div role={"button"}
                 className={"fs-5"}
                 onClick={handleClickMarkAllAsReadButton}
            >
                {
                    paginatedUserNotifications.isFetching
                        ? <Spinner size={'sm'}/>
                        : '全部已讀'
                }
            </div>
        </div>
    )
}
