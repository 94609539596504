import {useState} from "react";
import ReferralTabs from "./ReferralTabs";
import ReferralCodeSection from "./ReferralCodeSection";
import {useRecoilState} from "recoil";
import userAtom from "../../States/user";
import EnterReferralCodeSection from "./EnterReferralCodeSection";

export default function ReferralCode() {
    const [userData] = useRecoilState(userAtom)
    const [clickedTab, setClickedTab] = useState('my-referral-code')
    const [showCopied, setShowCopied] = useState(false)

    return (
        <div className={"container px-4"} style={{maxWidth: "400px"}}>
            <div className={"mt-4 fs-1"}>推薦碼</div>
            <ReferralTabs clickedTab={clickedTab} setClickedTab={setClickedTab}/>
            <div className={"mt-5"}>
                {
                    clickedTab === 'my-referral-code'
                        ?
                        <ReferralCodeSection referralCode={userData?.referral_code} showCopied={showCopied}
                                             setShowCopied={setShowCopied}/>
                        :
                        <EnterReferralCodeSection userData={userData}/>
                }
            </div>
        </div>
    )
}