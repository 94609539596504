import {isLoggedIn} from "../../Helpers";
import DisconnectWalletButton from "./DisconnectWalletButton";
import {Link} from 'react-router-dom'
import Loading from "../Loading";
import {useLogout} from "../../APIs/apis";

const NavItems = ({closeNavbar = null}) => {
    const logout = useLogout()

    const handleCloseNavbar = () => {
        if (closeNavbar !== null) {
            closeNavbar()
        }
    }

    return (
        <>
            <div>
                <Link to={"/"} className={"nav-link text-black"} aria-current={"page"}
                      onClick={() => handleCloseNavbar()}>首發</Link>
            </div>
            <div>
                <Link to="/explore" onClick={handleCloseNavbar} className="nav-link text-black"
                      aria-current="page">探索</Link>
            </div>
            <div>
                <Link to={"/market"} onClick={handleCloseNavbar} className={"nav-link text-black"}
                      aria-current={"page"}>交易市場</Link>
            </div>
            {
                isLoggedIn() &&
                <div>
                    <Link to="/wallet" onClick={handleCloseNavbar} className="nav-link text-black"
                          aria-current="page">我的錢包</Link>
                </div>
            }
            {
                logout.isLoading ? <span
                    className={"nav-link text-black d-flex"}><Loading/></span> : isLoggedIn() &&
                    <DisconnectWalletButton
                        handleDisconnect={async () => await logout.mutateAsync()}/>
            }
            {
                !isLoggedIn() &&
                <>
                    <div>
                        <Link to="/registration" onClick={handleCloseNavbar} className="nav-link text-black"
                              aria-current="page">註冊</Link>
                    </div>
                    <div>
                        <Link to="/login" onClick={handleCloseNavbar} className="nav-link text-black"
                              aria-current="page">登入</Link>
                    </div>
                </>
                // <ConnectMetamaskButton handleConnect={async () => await login.mutateAsync()}/>
            }
            <div>
                <Link to={"/more"} onClick={handleCloseNavbar} className={"nav-link text-black"}
                      aria-current={"page"}>更多</Link>
            </div>
            {/*{connectTronLink()}*/}

        </>
    )
}
export default NavItems;
