export default function LoadMorePages({
                                          pagination,
                                          loadMoreText,
                                          lastPageText = '已是最後一頁',
                                          loadingText = '讀取中...',
                                          reference
                                      }) {
    return (
        <button className="text-center form-control border-radius-five border-2 p-2"
                ref={reference}
                style={{borderColor: "#00C889", borderStyle: "solid"}}
                disabled={!pagination.hasNextPage}
                onClick={() => {
                    pagination.fetchNextPage()
                }}>{!pagination.hasNextPage
            ? lastPageText
            : pagination.isFetching
                ? loadingText
                : loadMoreText}

        </button>
    )
}