import {useSearchParams} from "react-router-dom";
import ConnectWarning from "../Components/ConnectWarning";
import {useEffect, useState} from "react";
import {useNft, usePurchaseNft, useRefreshBalances} from "../APIs/apis";
import {useUserSelf} from "../APIs/apis";
import {Alert} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import {useQueryClient} from "@tanstack/react-query";
import {Spinner} from "react-bootstrap";
import PurchasingModal from "../Components/PurchasingModal";
import {useRecoilState} from "recoil";
import purchaseModalAtom from "../States/purchaseModal";

export default function Purchase() {
    const [searchParams] = useSearchParams();
    const [showBalanceNotEnough, setShowBalanceNotEnough] = useState(false)
    const nftId = searchParams.get("nft_id")
    const nft = useNft(nftId, {
        enabled: !!nftId
    })
    const [showPurchaseModal, setShowPurchaseModal] = useRecoilState(purchaseModalAtom)
    const navigate = useNavigate()
    const user = useUserSelf()
    const [confirmed, setConfirmed] = useState(false)
    const refreshBalances = useRefreshBalances()
    const queryClient = useQueryClient()
    const purchaseNft = usePurchaseNft(nftId, nft)

    useEffect(() => {
        if (purchaseNft.isSuccess) {
            setShowPurchaseModal(false)
        }
    }, [purchaseNft, setShowPurchaseModal])

    useEffect(() => {
        refreshBalances.mutateAsync()
        queryClient.invalidateQueries('user-self')
    }, [refreshBalances, queryClient])

    useEffect(() => {
        // 代表非出售中的 NFT
        if (nft.isSuccess && nft.data.nft_price === null) {
            navigate('/')
        }
    }, [nft, navigate])

    useEffect(() => {
        if (user.isSuccess && nft.isSuccess && user.data.id === nft.data.owner.id) {
            navigate('/')
        }
    }, [user, nft, navigate])

    const getNftPriceString = () => {
        return `${Number(nft.data.nft_price).toFixed(4)} ${nft.data.currency}`
    }

    const getUserBalanceByChainId = (user, chainId) => {
        return user.data.balances.find(balance => balance.chain_id === chainId).balance
    }

    const handleSubmit = async () => {
        if (parseFloat(getUserBalanceByChainId(user, nft.data.chain_id)) < parseFloat(nft.data.nft_price)) {
            setShowBalanceNotEnough(true)
            return
        }

        setShowPurchaseModal(true)
        await purchaseNft.mutateAsync()
    }

    return (
        nft.isSuccess &&
        <>
            <section className="py-2">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pe-lg-5">
                            <div className="sidebar-fix card osahan-item-list bg-transparent border-0">
                                <img src={nft.data.nft_image_url} alt={nft.data.nft_name}
                                     className="img-fluid w-100 rounded shadow"/>
                                <div className="card-header border-0 bg-transparent p-0 mb-3 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <div><img src={nft.data?.nft_group?.series.series_image_url}
                                                      alt={nft.data?.nft_group?.series.series_name}
                                                      className="img-fluid rounded-circle"/></div>
                                            <div
                                                className="small fw-bold">{nft.data?.nft_group?.series.series_name}</div>
                                        </div>
                                    </div>
                                </div>
                                <h2 className="mb-3">{nft.data.nft_name}</h2>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="mt-4">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6>價格</h6>
                                    </div>
                                    <div>
                                        <h6>{getNftPriceString()}</h6>
                                    </div>
                                </div>
                                <hr className="mb-2 mt-1"/>
                                <div className={"d-flex justify-content-end"}>≈ {nft.data.nft_price_in_usd} USD</div>
                            </div>

                            <div className="mt-5">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <h6>{nft.data.currency} 付款</h6>
                                    </div>
                                    <div>
                                        <h6>購買</h6>
                                    </div>
                                </div>
                                <hr className="mb-2 mt-1"/>
                                <div className="d-flex justify-content-between">
                                    {
                                        user.isSuccess &&
                                        <>
                                            <div>
                                                目前餘額
                                            </div>
                                            <div>
                                                {Number(user.data.balances.find(balance => balance.chain_id === nft.data.chain_id).balance).toFixed(4)} {user.data.balances.find(balance => balance.chain_id === nft.data.chain_id).currency}
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>
                            <Alert className={"mt-5"} variant="danger" show={showBalanceNotEnough}
                                   onClose={() => setShowBalanceNotEnough(false)}
                                   dismissible>
                                <Alert.Heading>餘額不足</Alert.Heading>
                                <p>
                                    您的錢包餘額不足, 請先行儲值您的錢包, 再繼續完成購買。
                                </p>
                            </Alert>
                            <div className="form-check mt-5 mb-3">
                                <input className="form-check-input" type="checkbox" checked={confirmed}
                                       onChange={() => setConfirmed(!confirmed)}/>
                                <p>我已了解這次購買一旦完成後即無法要求退款。</p>
                            </div>
                            <div className="mb-4 mt-2">
                                <form onSubmit={(event) => {
                                    event.preventDefault()
                                    handleSubmit()
                                }}>
                                    <button
                                        className="btn w-100 btn-primary bg-black btn-lg rounded-lg"
                                        disabled={!confirmed || purchaseNft.isLoading}
                                    >
                                        {purchaseNft.isLoading ? <><Spinner
                                            as="span"
                                            variant="light"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                            animation="border"/><span> 交易中, 請耐心等候</span></> : '確認付款 ' + getNftPriceString()
                                        }
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ConnectWarning/>
            <PurchasingModal show={showPurchaseModal} onHide={() => setShowPurchaseModal(false)}
                             isPurchasing={purchaseNft.isLoading}
                             purchaseNft={async () => await purchaseNft.mutateAsync()}/>
        </>
    )
}