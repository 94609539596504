import React, {useEffect, useState} from "react";
import NotificationsModal from "./NotificationsModal";
import {usePaginatedUserNotifications} from "../../APIs/apis";
import {useRecoilState} from "recoil";
import authAtom from "../../States/auth";
import {useQueryClient} from "@tanstack/react-query";

const NotificationBell = ({hasUnreadNotifications = false}) => {
    const [auth] = useRecoilState(authAtom)
    const queryClient = useQueryClient()
    const handlePaginatedUserNotificationsSuccess = (data) => {
        setHasNotifications(data?.pages[0]?.data?.length !== 0)
    }
    const paginatedUserNotifications = usePaginatedUserNotifications({
        enabled: !!auth.accessToken,
        onSuccess: handlePaginatedUserNotificationsSuccess
    })
    const [showUserNotifications, setShowUserNotifications] = useState(false)

    const [hasNotifications, setHasNotifications] = useState(false)

    useEffect(() => {
        queryClient.invalidateQueries("paginated-user-notifications")
    }, [hasUnreadNotifications, queryClient])
    return (
        <>
            <div className={"position-relative"}>
                <button className={"border-0 bg-transparent p-0 stretched-link"}
                        onClick={() => setShowUserNotifications(true)}
                ><i className="bi bi-bell"
                    style={{fontSize: "25px"}}></i>
                </button>
                {
                    hasUnreadNotifications &&
                    <div
                        className={"text-danger position-absolute bg-danger border-radius-five border border-2 border-white"}
                        style={{top: "8px", right: "1px", width: "12px", height: "12px"}}>
                    </div>
                }
            </div>
            <NotificationsModal show={showUserNotifications} onHide={() => setShowUserNotifications(false)}
                                hasUnreadNotifications={hasUnreadNotifications}
                                paginatedUserNotifications={paginatedUserNotifications}
                                hasNotifications={hasNotifications}
            />
        </>
    );
}

export default NotificationBell