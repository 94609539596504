export default function ClientService() {
    return (
        <div>
            <div className={"one-dream-footer-title mb-2"}>客戶服務信箱</div>
            <div className={"one-dream-footer-text"}><a href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                                                        target={"_blank"}
                                                        rel="noreferrer noopener"
            >resnly.service@gmail.com</a></div>
        </div>
    )
}