import React from "react";
import {Link} from "react-router-dom";

export default ({series, isSliderItems}) => {
    const colSetting = "col-6 col-md-4 col-lg-2"
    return (
        <div className={"card p-3 rounded bg-transparent border-0 " + (isSliderItems ? '' : colSetting)}>
            <div className={"series-card-img-frame rounded"}>
                <img src={series.series_image_url} className="img-fluid w-100"
                     alt={series.series_name}/>
            </div>
            <Link to={`/single-series?id=${series.id}`} className={"stretched-link"}/>
            <span className="fw-bold">{series.series_name}</span>
        </div>
    )
}