export default function questionsAndAnswers() {
    return [
        {
            q: '如何在 OneDream 獲取 NFT？',
            a: '在 OneDream，您可以通過完成各項站內活動（例如註冊好禮、推薦碼等）來獲取抽取NFT的機會。'
        },
        {
            q: '這些 NFT 可以做什麼？',
            a: '我們將會陸續推出各種實質性的獎勵，這些獎勵將會與您獲得的 NFT 數量相對應，敬請期待！'
        },
        {
            q: '領取 NFT 要錢嗎？', a: '您無需支付任何成本即可領取NFT。'
        },
        {
            q: '沒有錢包該怎麼辦？',
            a: '若您沒有 Metamask 錢包，可參考 <a href="https://www.grenade.tw/blog/what-is-metamask" target="_blank">【此處】</a> 提供之創建 Metamask 錢包教程，創建完成後就可繼續暢遊 OneDream 拿好禮！'
        },
        {
            q: '我可以自己鑄造 NFT 嗎？',
            a: '由於 OneDream 是一個以影劇、動漫類型為主的 NFT 平台，其性質相對較為特殊，因此目前不開放用戶自行鑄造 NFT ，所有 NFT 都是由團隊人員進行鑄造。'
        },
        {
            q: '為什麼會有交易市場？',
            a: '因 OneDream 的交易系統雖然目前處於關閉狀態，但我們會在未來考慮用戶人數和市場反應，進一步開發買賣功能；且每一個 NFT 仍然保有自身的價值，在「最高成交紀錄」中，您可以看到哪些 NFT 已經被抽取，以及它們的價格。'
        },
        {
            q: '可以詳細說明推薦碼嗎？',
            a: '每位完成註冊的用戶都會獲得一組專屬推薦碼。 </br> 當您將此推薦碼分享給好友，好友首次在OneDream輸入您的推薦碼後，您和好友都可獲得一次抽取NFT的機會。專屬推薦碼可以被多人輸入，但同一人不能重複輸入。 </br> 若您想獲得更多抽取NFT的機會，就請分享給更多好友吧！'
        },
        {
            q: '補發 NFT 是什麼意思？',
            a: '如果您在領取時遇上補發的情況，敬請等候，我們將確保在系統公告時段內、將其歸戶至您的「我的錢包 > 我的 NFT > 收藏」中。'
        },
        {
            q: '為什麼我沒辦法立即收到NFT？',
            a: '<p>通常為了確保交易的安全性和可信度，區塊鏈交易需要經過多個步驟進行驗證和加密，為了讓您不必等待畫面跳轉過久，我們採用非同步的方式，讓您可以繼續使用OneDream。 </p> <p>您可以立即知道抽到了哪張NFT，當NFT鑄造完成後，它會自動轉移到您的帳戶下，您也可以由站內通知得知NFT的鑄造情況。</p>'
        },
    ]
}