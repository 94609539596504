import {Modal, ModalBody} from "react-bootstrap";
import presentImg from "../Components/Assets/present.png"
import {Link} from "react-router-dom";

export default function GainClaimableNft({show, onHide}) {
    return (
        <Modal show={show} onHide={onHide} contentClassName={"rounded-modal-border text-center p-2"}>
            <Modal.Header closeButton className={"border-0"}>
            </Modal.Header>
            <ModalBody>
                <div>
                    <img src={presentImg} alt={"present-img"} className={"img-fluid"}/>
                </div>
                <div className={"mt-2 fs-3 fw-bold"}>您將獲得抽取 NFT 一次的機會!</div>
                <div className={"mt-2 fs-6"}>點擊下方按鈕，至「更多 > 兌換獎勵」領取</div>
                <button className={"mt-5 one-dream-button form-control position-relative"}>
                    <Link to={'/claim-nfts'} className={"stretched-link"}/>
                    兌換獎勵
                </button>

            </ModalBody>
        </Modal>
    )
}