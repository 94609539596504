import {useMarkNotificationAsRead} from "../../APIs/apis";
import {useQueryClient} from "@tanstack/react-query";

export default function UserNotification({notification}) {
    const queryClient = useQueryClient()
    const markNotificationAsRead = useMarkNotificationAsRead({enabled: !!notification.id}, notification?.id)

    const handleMarkNotificationAsRead = async () => {
        if (notification.read_at === null) {
            await markNotificationAsRead.mutateAsync()
            queryClient.invalidateQueries('paginated-user-notifications')
        }
    }

    return (
        <div className={"position-relative border-bottom pb-3"}
             role={notification.read_at === null ? 'button' : ''}
             onClick={handleMarkNotificationAsRead}
        >
            {
                notification.read_at === null &&
                <div className={"border rounded-circle position-absolute"}
                     style={{
                         width: '10px',
                         height: '10px',
                         backgroundColor: "red",
                         top: "10px",
                         left: '5px'
                     }}
                />
            }
            <div className={"ms-4"}>
                <div className={"fs-5 fw-bold"}>
                    {notification?.title}
                </div>
                <div className={"mt-2 fs-5"}>{notification.content}</div>

                <div
                    className={"mt-2 fs-6 fw-light"}>{notification.notified_at_text}</div>
            </div>
        </div>
    );
}