import React from "react";

export default function privacyAndUserTerm() {
    return (
        <div className={"container"}>
            <h1 className={"mb-4"}>使用者條款暨隱私權政策</h1>
            <p>
                OneDream 由台灣銘板股份有限公司（以下稱「本公司」）所經營；下列各項條款（下稱「本條款」）適用於使用網站或透過網站購買之用戶交易，請您於使用本網站前仔細閱覽各項條款。
            </p>

            <p>
                本條款係用戶與本公司之間具有拘束力之契約，本公司保留更新本條款與條件之權利，亦即本公司得隨時修訂、變更或更新本條款。若有任何修訂、變更或更新本條款，本公司會將相關變動通知您，讓您能夠決定是否繼續依據後續版本條款之規定存取網站。請注意，於任何修訂、變更或更新生效日後，若您不同意任何
                此等後續修訂、變更或更新內容，您將無法繼續使用本網站。
            </p>

            <p>
                1. 接受條款：
                若您以任何方式存取網站或透過網站購買產品（包括但不限於透過任何其他第三方提供之裝置或服務），則視為您已接受並同意受本條款所拘束。
            </p>

            <p>
                2. 交易及後續服務：
            </p>
            <p>
                2.1.
                本網站之商品屬非以有形媒介提供之數位內容或一經提供即為完成之線上服務，
                且您已事先確認並同意所購買之商品，本公司始提供之。依通訊交易解除權合
                理例外情事適用準則第2 條第5 款規定，本網站之商品排除消費者保護法第19
                條第1 項解除權之適用。
            </p>
            <p>
                2.2.
                本公司無償提供之贈品，不得向本公司請求變價、退款、抵銷或其他類似主張。
            </p>
            <p>
                2.3.
                本網站之商品如於本網站用戶間自由轉讓，本公司僅作為服務平台，並無介入
                任何二次（次級）交易，如有交易糾紛本公司不負任何擔保或賠償責任。
            </p>
            <p>
                3. 帳戶註冊安全：
                使用本網站購買產品時，您須註冊本網站之用戶帳號。於註冊過程中，將會要求您設定用戶帳戶及密碼。若您同意註冊及存取網站，代表您同意：(a)
                提供與您有關之正確、最新且完整的資訊，且後續將即時更新此等資訊以維持其正確、最新且完整狀態；(b) 維持身份資訊之安全；及
                (c)
                接受因不正確、非最新且不完整資訊而導致之所有風險。此外，您不得將您的用戶帳戶及密碼提供給其他第三方，若因非本公司之疏失導致您的用戶帳戶及密碼遭第三方使用，本公司不負任何責任，不法行為之人應自負刑事責任。
            </p>

            <p>
                4. 智慧財產權及使用方式：
                本網站刊載之所有內容，包含但不限於文字、照片、影像、插圖、錄音、影片、檔案及網頁設計或透過任何媒體形成之資料，均受到中華民國著作權法及國際著作權法律的保障。本網站之智慧財產權（包括但不限於商標權、專利權、著作權、營業秘密與專有技術等）均由原權利人所有。如您有任何重製、改作或公開傳輸等侵害本公司以外他人之任何智慧財產權之行為，應自行負責，與本公司無涉。
            </p>

            <p>
                5. 第三方服務聲明：
                本網站得與任何第三方服務合作，以提升產品及服務予消費者，當您使用本網站購買產品時，即表示您同意與授權本公司及於任何時點與第三方服務合作。因任何第三方服務而產生或相關之任何與所有求償、負債、損失、損害、駭客入侵、未經授權存取或使用，皆應由提供此等第三方服務之服務提供方負責。您另同意，本公司不須就任何第三方服務負擔任何責任或義務。
            </p>

            <p>
                6. 隱私權條款：
                為了支持個人資料保護，維護個人隱私權，本公司謹以下列聲明，向您說明本公司蒐集個人資料之目的、類別、利用範圍及方式、以及您所得行使之權利等事項：
            </p>

            <p>
                6.1. 個人資料取得之適用範圍：
                本公司隱私權聲明及其所包含之告知事項，僅適用於本網站及其相關服務。本網站內可能包含許多連結、嵌入式畫面或其他合作夥伴所提供的服務，關於該等連結網站或合作夥伴網站的隱私權聲明及與個人資料保護有關之告知事項，請參閱各該連結網站或合作夥伴網站，其他連結網站或合作夥伴網站所蒐集之任何個人資料，皆與本公司及本網站無關。
            </p>

            <p>
                6.2. 個人資料蒐集之目的與類別：
                本公司為了行銷、客戶管理與服務、開發新服務、履行法定或合約義務、保護當事人及相關利害關係人之權益、以及經營合於營業登記項目或組織章程所定之業務等目的，依照各該服務之性質，可能蒐集您的姓名、身份證字號、連絡方式（包括但不限於電話號碼、E-MAIL及地址等）、服務單位、職稱、您的收款及付款記錄、本公司為代為完成收款或付款所需之金融資料（信用卡號、有效年月、安全碼）、IP位址、手機識別碼、使用者所在的地理位置、及其他得以直接或間接識別使用者身分之個人資料處理與利用。
                此外，為提升服務品質，本公司可能依照所提供服務之性質，記錄使用者所處的地理位置、IP位址、以及在本公司相關網站內的瀏覽活動（例如，使用者所使用的軟硬體、所點選的網頁、查詢之紀錄）等資料，但是這些資料僅供作風險控管分析、流量分析和網路行為調查，以便於改善本公司相關網站的服務品質，這些資料也只是總量上的分析，不會和特定個人相連繫。

            </p>


            <p>
                6.3. 個人資料的利用：
                本公司所蒐集的足以識別使用者身分的個人資料，都僅供本公司於其內部、依照蒐集之目的進行處理和利用，除非事先說明、或為完成提供服務或履行合約義務之必要、或依照相關法令規定或有權主管機關之命令或要求、或基於與合作夥伴共同開發新服務予使用者，否則本公司不會將足以識別使用者身分的個人資料提供給第三人（包括境內及境外）、或移作蒐集目的以外之使用。

            </p>


            <p>
                6.4. 保管資料期間：
                在合約有效期間內，以及法令所定應保存之期間內，本公司會持續保管、處理及利用相關資料。

            </p>


            <p>
                6.5. 資料當事人之權利：
                資料當事人可以請求查詢、閱覽本人的個人資料或要求給予複本，但本公司得酌收必要成本和費用。若您的個人資料有變更、或發現您的個人資料不正確，可以向本公司要求修改或更正。當蒐集個人資料之目的消失或期限屆滿時，您可要求刪除、停止處理或利用個人資料，但因本公司執行職務或業務所必須者，不在此限。

            </p>


            <p>
                6.6. 資料當事人之行使方式：
                資料當事人得來電本公司客服單位提出申請，本公司將依據您提出可資確認之身分證明文件申請。若您委託他人代為申請者，請另外提出可資確認的身分證明。若您申請查詢、閱覽以及製給個人資料，本公司得酌收相當費用。

            </p>

            <p>
                7. 限制行為：
                您使用本網站不得有下列行為：

            </p>


            <p>
                7.1. 開發、重製或仿製本公司之服務或商品，包括但不限於模組、設計、介面，外觀及排版；

            </p>

            <p>
                7.2. 對本網站及商品進行逆向工程、解編、拆解或以其他方式獲得原始碼；

            </p>

            <p>
                7.3. 傳播惡意軟體、程式、病毒、電腦蠕蟲或以其他方式破壞本網站及商品；

            </p>

            <p>
                7.4. 轉讓、販賣、租賃或授權您於本網站註冊之帳戶予第三方持有或使用；

            </p>

            <p>

                7.5. 刪除或更改本網站及商品中之任何內容；
            </p>


            <p>
                7.6. 未經許可而進入或篡改本網站及商品之電腦系統或供應商技術交付系統上之非公共區域；

            </p>

            <p>
                7.7. 尋找或測試任何本網站及商品系統之漏洞，或違反、規避任何安全或認證措施；

            </p>

            <p>
                7.8. 在任何電子郵件或貼文中偽造任何TCP / IP數據標頭(TCP/IP packet header)或標頭資訊(header
                information)的任何部分，或以任何方式使用本網站及商品發送更改、欺詐或虛假的來源識別資訊；

            </p>

            <p>

                7.9. 繞過、刪除、停用、損害、解碼或以其他方式規避本網站及商品、任何本公司供應商或任何第三方（包括其他使用者）所實施保護本網站及商品之內容之任何技術措施；或
            </p>

            <p>
                7.10. 以任何方式干擾本網站、商品、其他使用者、本公司主機及網路之運作，或有干擾本網站及FortVax 冷錢包之虞。

            </p>

            <p>
                8. 防制洗錢及打擊資恐條款
                本公司施行相關反洗錢及打擊資恐之法律規定、政策和程序，包括監控可疑交易，以及向監理機構報告。本公司根據其適用的法律、政策以及履行與第三方的契約義務，需要保存部分資訊和文件，本公司特此明確表示其有保存該資訊和文件的權利，並有權向政府機關揭露該資訊和文件。即使您與本公司之法律關係終止，本條款仍應適用：

            </p>

            <p>
                8.1.
                為符合防制洗錢及打擊資恐之相關法規規範，您及/或您的關聯人（包含但不限於法定代理人、負責人、代表人、被授權人、實質受益人等）同意本公司得分別依「洗錢防制法」或「資恐防制法」等相關規定，進行以下措施，您及/或您的關聯人並同意，就本公司依本條辦理相關措施所導致您及/或您的關聯人產生之任何損害或不利益，均由您及/或您的關聯人自行承擔，本公司不負損害賠償責任：

            </p>

            <p>
                8.2.
                本公司於發現您及/或關聯人為受經濟制裁、外國政府或國際洗錢防制組織認定或追查之恐怖分子或團體者時，本公司得逕行暫時停止並終止本公司服務、本使用條款與其相關之各項交易與業務關係，而無須另行通知您及/或您的關聯人。

            </p>

            <p>
                8.3.
                本公司於定期或不定期審查您及/或您的關聯人身份作業或於其他認為必要之情形時（包括但不限於：懷疑您及/或您的關聯人涉及非法活動、疑似洗錢、資恐活動、或經媒體報導，您及/或您的關聯人涉及任何違法之特殊案件等)，得要求您於接獲本公司通知後14天（含）內提供審查所需之必要資料，若您及/或您的關聯人逾期仍不履行者，本公司得逕行暫時停止並終止提供予您之服務、本使用條款與其相關之各項交易與業務關係，而無須另行通知您及/或您的關聯人。

            </p>

            <p>
                9. 損害賠償：
                您同意就因：(a) 您違反本條款之行為；及／或 (b)
                您違反任何第三方權利，包括但不限於任何智慧財產、機密資訊或其他專屬權利等事項而導致本公司、本公司關係企業及本公司之合作夥伴（人員包括但不限於董事、經理人、員工或代理人等）之任何損害、負債、損失及費用負擔所有損害賠償責任，賠償範圍包括但不限於合理的法律、會計服務費。

            </p>

            <p>

                10. 訴訟管轄：
                本條款之解釋與適用，以及因本條款所生之爭議，您同意依中華民國法律處理，並以臺灣臺北地方法院為第一審管轄法院。
            </p>

            <p>
                11. 聯繫我們：
                如果您對於本公司的使用者條款暨隱私權聲明有任何疑問，可以和本公司客服中心（電子信箱：resnly.service@gmail.com）聯絡，本公司將儘快回覆說明。
            </p>

        </div>
    )
}