export function getCurrentUri(currentLocation) {
    return process.env.REACT_APP_DOMAIN + currentLocation.pathname + currentLocation.search
}

export function getMetamaskDAppDeepLink(currentLocation) {
    return `https://metamask.app.link/dapp/${getCurrentUri(currentLocation)}`
}

export function isLoggedIn() {
    return localStorage.getItem('isLogin') || false
}

export function getBlockchainLogo(chainId) {
    switch (chainId) {
        case 80001:
        case 137:
            return 'assets/img/blockchain-polygon.svg'
    }
}

export function isValidEmailPattern(email) {
    const regexRule = /^\w+([\+\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    return regexRule.test(email)
}

export function isValidPasswordFormat(password) {
    const regexRule = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]{8,16}$/
    return regexRule.test(password)
}

export function getChainIdPerEnv() {
    const env = process.env.REACT_APP_ENV
    switch (env) {
        case 'local':
        case 'stage':
            return 80001
        case 'production':
            return 137
        default:
            return 80001
    }
}

export function handleFormChangeTemplate(e, setForm) {
    if (e.target.type === 'checkbox') {
        setForm((prev) => {
            const key = e.target.name
            return {
                ...prev,
                [e.target.name]: !prev[key]
            }
        })
        return
    }

    setForm((prev) => {
        return {
            ...prev,
            [e.target.name]: e.target.value
        }
    })
}

export function getNetworkInfo(chainId) {
    const chainIdInHex = `0x${chainId.toString(16)}`
    switch (chainId) {
        case 80001:
            const mumbaiRpcUrl = process.env.REACT_APP_MUMBAI_RPC_URL
            const mumbaiExplorerUrl = process.env.REACT_APP_MUMBAI_EXPLORER_URL
            return {
                chainId: chainIdInHex,
                chainName: 'Mumbai',
                nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18,
                },
                rpcUrls: [mumbaiRpcUrl],
                blockExplorerUrls: [mumbaiExplorerUrl]
            };
        case 137:
            const polygonRpcUrl = process.env.REACT_APP_POLYGON_RPC_URL
            const polygonExplorerUrl = process.env.REACT_APP_POLYGON_EXPLORER_URL
            return {
                chainId: chainIdInHex,
                chainName: 'Polygon',
                nativeCurrency: {
                    name: 'MATIC',
                    symbol: 'MATIC',
                    decimals: 18,
                },
                rpcUrls: [polygonRpcUrl],
                blockExplorerUrls: [polygonExplorerUrl]
            };
    }
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(() => resolve(), ms))
}

export function getRoundedNftPrice(nftPrice, digits = 4) {
    return Number(nftPrice).toFixed(digits)
}