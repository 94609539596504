import React, {useEffect} from "react";
import {Outlet} from "react-router-dom";
import {useRecoilState} from "recoil";
import authAtom from "../States/auth";
import {useNavigate} from "react-router-dom";
import {useLocation} from "react-router-dom";

export default function ProtectedRoute({redirectPath = '/'}) {
    const location = useLocation()
    const [auth] = useRecoilState(authAtom)
    const navigate = useNavigate()

    useEffect(() => {
        if (!auth?.accessToken) {
            localStorage.removeItem('isLogin')
            navigate(redirectPath, {state: {from: location}, replace: true});
        }
    })

    return <Outlet/>
}