import React, {useState} from "react";
import {useSearchParams} from "react-router-dom";
import NftGroupCard from "../Components/NftGroupCard";
import ReadMore from "../Components/ReadMore";
import {useActor, useNftGroups} from "../APIs/apis";
import _ from "lodash"
import dayjs from "dayjs";
import Loading from "../Components/Loading";
import {getRoundedNftPrice} from "../Helpers";

const SingleActor = () => {
    const [searchParams] = useSearchParams();
    const [processNftGroups, setProcessNftGroups] = useState(true)
    const actorId = searchParams.get('id')
    const [groupedNftGroups, setGroupedNftGroups] = useState([])
    const actor = useActor(actorId, {
        enabled: !!actorId
    })

    const handleQueryNftGroupsSuccess = (data) => {
        if (!processNftGroups) {
            return
        }
        setProcessNftGroups(false)
        setGroupedNftGroups(() => {
            return _.groupBy(data, nftGroup => {
                return dayjs(nftGroup.created_at).format('YYYY')
            })
        })
    }

    useNftGroups({
        enabled: !!actorId,
        onSuccess: handleQueryNftGroupsSuccess,
    }, [], [actorId],)

    return (
        <main>
            {
                actor.isLoading ? <Loading/> : actor.isSuccess &&
                    <>
                        <div className="card bg-transparent border-0">
                            <img className="img-fluid single-actor-top-image" alt={actor.data.actor_name}
                                 src={actor.data.actor_banner_image_url}/>
                            <div className="mx-auto"><img className="img-fluid rounded-circle profile" alt=""
                                                          src={actor.data.actor_avatar_image_url}/></div>

                        </div>
                        <div className="text-center container mt-3">
                            <h2>{actor.data.actor_name}</h2>
                            {
                                <ReadMore text={actor.data.actor_description}/>
                            }
                        </div>
                        <div className="d-flex justify-content-around mt-3">
                            <div className="d-block">
                                <h6 className="text-center">{actor.data.actor_owned_nft_count}</h6>
                                <p className="text-center">NFT 數量</p>
                            </div>
                            <div className="d-block">
                                <h6 className="text-center">{actor.data.actor_owned_nft_user_count}</h6>
                                <p className="text-center">擁有者</p>
                            </div>
                            <div className="d-block">
                                <h6 className="text-center">MATIC {getRoundedNftPrice(actor.data.actor_owned_nft_min_price)}</h6>
                                <p className="text-center">最低價</p>
                            </div>
                            <div className="d-block">
                                <h6 className="text-center">MATIC {getRoundedNftPrice(actor.data.actor_owned_nft_sold_amount_sum)}</h6>
                                <p className="text-center">總交易量</p>
                            </div>
                        </div>
                    </>
            }
            {
                !_.isEmpty(groupedNftGroups) &&
                Object.keys(groupedNftGroups).reverse().map((year, index) => {
                    const nftGroupsByYear = groupedNftGroups[year]
                    return (
                        <div key={index}>
                            <h3 className="fw-light text-center mt-3">{year === dayjs().format('YYYY') ? 'THIS YEAR' : year}</h3>
                            <hr/>
                            <div className="row px-3">
                                {
                                    nftGroupsByYear.map((nftGroup, index) => {
                                        return <NftGroupCard nftGroup={nftGroup} isSliderItem={false} key={index}/>
                                    })
                                }
                            </div>
                        </div>
                    )
                })
            }
        </main>
    )
}

export default SingleActor