import {Modal} from "react-bootstrap";
import {Link} from "react-router-dom";
import {useState} from "react";

export default function OnSaleNftVersions(props) {
    const onSaleNftsCount = props.onSaleNfts.length
    const [onSaleNftVersion, setOnSaleNftVersion] = useState(undefined)

    function getSingleOnSaleNftStyle(index, onSaleNftsCount) {
        let commonStyle = "d-flex justify-content-between p-2 border border-dark "
        if (index === 0) {
            return commonStyle + "border-bottom-0 rounded-top"
        } else if (index === onSaleNftsCount - 1) {
            return commonStyle + "border-bottom rounded-bottom"
        } else {
            return commonStyle + "border-bottom-0"
        }
    }

    return (
        <Modal show={props.show} onHide={props.onHide}>
            <Modal.Header closeButton>
                <Modal.Title>選擇版本</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className={"fw-bold"}>{props.onSaleNfts.length} 種版本可選擇</p>
                <div>
                    {props.onSaleNfts.map((nft, index) => {
                        return (
                            <div
                                key={index}
                                className={getSingleOnSaleNftStyle(index, onSaleNftsCount)}>
                                <div className={"d-flex align-items-center gap-2"}>
                                    <input
                                        id={`onSaleNft-${nft.id}`}
                                        type="radio"
                                        value={nft.id}
                                        name="onSaleNftVersions"
                                        onChange={() => {
                                            setOnSaleNftVersion(nft)
                                        }}
                                        checked={onSaleNftVersion?.id === nft.id}
                                    />
                                    <label htmlFor={`onSaleNft-${nft.id}`}>{nft.nft_version}</label>
                                </div>
                                <div>
                                    {`${Number(nft.nft_price).toFixed(4)} ${nft.currency}`}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </Modal.Body>
            {
                onSaleNftVersion &&
                (<Modal.Footer>
                    <Link to={`/nft?id=${onSaleNftVersion.id}`} className={"form-control btn btn-dark"}>
                        下一步
                    </Link>
                </Modal.Footer>)
            }
        </Modal>
    )
}