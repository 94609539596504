import LoadMorePages from "./LoadMorePages";
import NftHistory from "./NftHistory";

const NftTxRecordAccordionItem = ({nftTxRecords}) => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header" id="flush-nft-tx-records">
                <button className="accordion-button collapsed p-0 fw-bold" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapse-nft-tx-records"
                        aria-expanded="false"
                        aria-controls="flush-collapse-nft-tx-records">
                    NFT 交易歷史
                </button>
            </h2>
            <div id="flush-collapse-nft-tx-records" className="accordion-collapse collapse"
                 aria-labelledby="flush-nft-tx-records">
                <div className="accordion-body py-2 p-0">
                    {
                        nftTxRecords.isSuccess && nftTxRecords.data?.pages?.map((page, index) => {
                            return (
                                <NftHistory page={page} key={index}/>
                            )
                        })
                    }
                    <LoadMorePages pagination={nftTxRecords}
                                   loadMoreText={'查看更多交易歷史'}/>
                </div>
            </div>
        </div>
    )
}

export default NftTxRecordAccordionItem