import {isLoggedIn} from "../../Helpers";
import {Link} from "react-router-dom";
import {useRecoilState} from "recoil";
import {useLogout, useLoginWithMetaMask} from "../../APIs/apis";
import {useEffect, useState} from "react";
import LoginInputModal from "../LoginInputModal";
import MetamaskInstallModal from "../MetamaskInstallModal";
import loginModalAtom from "../../States/loginModal";
import metamaskInstalledAtom from "../../States/metamaskInstalled";
import userAtom from "../../States/user";
import {Collapse} from "react-bootstrap";
import NavItems from "./NavItems";
import NotificationBell from "./NotificationBell";
import MobileSearchBox from "./MobileSearchBox";
import DesktopSearchBox from "./DesktopSearchBox";
import MobileNavbarButton from "./MobileNavbarButton";

const Nav = () => {
    const [displayMobileSearchBox, setDisplayMobileSearchBox] = useState(false)
    const [navbarClicked, setNavbarClicked] = useState(false)
    const [metamaskInstalled, setMetamaskInstalled] = useRecoilState(metamaskInstalledAtom)
    const [userData] = useRecoilState(userAtom)
    const [loginModal, setLoginModal] = useRecoilState(loginModalAtom)
    const [search, setSearch] = useState('')
    const logout = useLogout()
    const login = useLoginWithMetaMask()

    useEffect(() => {
        setNavbarClicked(false)
    }, [displayMobileSearchBox])

    return (
        <>
            {
                displayMobileSearchBox
                    ?
                    <MobileSearchBox handleClose={() => setDisplayMobileSearchBox(false)}
                                     search={search}
                                     setSearch={setSearch}
                    />
                    :
                    <div>
                        <div className={"d-flex align-items-center p-3"}>
                            <div>
                                <Link to="/" className="navbar-brand">
                                    <img src="/assets/img/logo.svg" alt="logo" className="img-fluid"/>
                                </Link>
                            </div>
                            <div className={"w-100 d-flex align-items-center justify-content-between"}>
                                <div className="fs-5 fw-bold">
                                    <div className={"d-lg-flex d-none align-items-center"}>
                                        <NavItems login={login} logout={logout}
                                                  closeNavbar={() => setNavbarClicked(false)}/>
                                    </div>
                                </div>
                                <div className={"d-flex justify-content-end align-items-center gap-3"}>
                                    {
                                        isLoggedIn() &&
                                        <NotificationBell hasUnreadNotifications={userData?.has_unread_notifications}/>
                                    }
                                    <div className={"d-lg-none"}>
                                        <button className={"border-0 p-0 bg-transparent"}
                                                onClick={() => setDisplayMobileSearchBox(true)}
                                        >
                                            <i className="bi bi-search" style={{fontSize: "25px"}}></i>
                                        </button>
                                    </div>
                                    <div className={"d-none d-lg-flex"}>
                                        <DesktopSearchBox search={search} setSearch={setSearch}/>
                                    </div>
                                    <div className={"d-lg-none"}>
                                        <MobileNavbarButton navbarClicked={navbarClicked}
                                                            setNavbarClicked={setNavbarClicked}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Collapse in={navbarClicked}>
                            <div id="navCollapsedItems" className={"fs-5 d-lg-none"}>
                                <NavItems login={login} logout={logout} closeNavbar={() => setNavbarClicked(false)}/>
                            </div>
                        </Collapse>
                    </div>
            }
            <hr className={"text-muted"}/>
            <MetamaskInstallModal
                show={!metamaskInstalled}
                onHide={() => setMetamaskInstalled(true)}
            />
            <LoginInputModal
                show={loginModal}
                onHide={() => {
                    setLoginModal(false)
                }}
            />
        </>
    )
}

export default Nav;
