import React from "react";
import {Link} from "react-router-dom";
import NftImage from "./NftImage";

const NftGroupCard = ({nftGroup, isSliderItem}) => {
    const classesForRow = "col-6 col-md-3 col-lg-2"
    return (
        <div
            className={"card osahan-item-list rounded bg-transparent border-0 mb-3 " + (isSliderItem ? '' : classesForRow)}
        >
            <div className={"position-relative"}>
                <NftImage
                    imgSrc={nftGroup.nft_group_image_url}
                    imgAlt={nftGroup.nft_group_name + "_image"}
                />
                <Link to={`/nft-group?id=${nftGroup.id}`} className={"rounded-lg stretched-link"}/>
            </div>
            <div className="card-header border-0 bg-transparent p-0">
                <div className="card-body fw-bold px-0 pb-0 mb-2">
                    <h6 className="mb-1">{nftGroup.nft_group_name}</h6>
                </div>
                {
                    nftGroup.series &&
                    <div className="d-flex justify-content-between mb-2">
                        <div className="d-flex gap-2 align-items-center position-relative">
                            <div
                            ><img src={nftGroup.series.series_image_url} alt={nftGroup.series_name}
                                  className="rounded-circle"
                                  style={{objectFit: "cover", width: '30px', height: '30px'}}/></div>
                            <div className="small fw-bold">{nftGroup.series.series_name}</div>
                            <Link to={`/single-series?id=${nftGroup.series.id}`}
                                  className={"rounded-lg stretched-link"}/>
                        </div>
                    </div>
                }
            </div>
            <h6>{`${nftGroup.currency} ${Number(nftGroup.price).toFixed(4)}`}</h6>
        </div>
    )
}

export default NftGroupCard