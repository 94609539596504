import React, {useState} from "react";
import {Modal, ModalBody, ModalFooter} from "react-bootstrap";
import {useRecoilState} from "recoil";
import GettingStartedGuideAtom from "../../States/gettingStartedGuide";
import mobileStepOneImage from "./Assets/mobileStep1.jpg"
import mobileStepTwoImage from "./Assets/mobileStep2.jpg"
import desktopStepOneImage from "./Assets/desktopStep1.jpg"
import desktopStepTwoImage from "./Assets/desktopStep2.jpg"
import {isMobile} from "react-device-detect";
import {useUpdateUser} from "../../APIs/apis";

export default function GettingStartedGuide() {
    const [currentStep, setCurrentStep] = useState(1)
    const [showGettingStartedGuideModal, setShowGettingStartedGuideModal] = useRecoilState(GettingStartedGuideAtom)
    const updateUserMutation = useUpdateUser({
        was_getting_started_guide_displayed: true
    })
    const handleCloseGuideModal = () => {
        setShowGettingStartedGuideModal(false)
        updateUserMutation.mutate()
    }
    const getGuideImage = () => {
        const template = (img) => {
            if (currentStep === 1) {
                return <img src={img} alt={'新手導覽第一步_image'} className={"img-fluid"}/>
            } else if (currentStep === 2) {
                return <img src={img} alt={'新手導覽第二步_image'} className={"img-fluid"}/>
            }
        }

        if (currentStep === 1) {
            if (isMobile) {
                return template(mobileStepOneImage)
            }
            return template(desktopStepOneImage)
        } else if (currentStep === 2) {
            if (isMobile) {
                return template(mobileStepTwoImage)
            }

            return template(desktopStepTwoImage)
        }
    }

    const getSize = () => {
        if (isMobile) {
            return 'sm'
        } else {
            return 'xl'
        }
    }

    const getLeftButton = () => {
        if (currentStep === 1) {
            return <div className={"fw-light py-2 px-3"} role={"button"}
                        onClick={() => handleCloseGuideModal()}>略過</div>
        }

        return <div className={"fw-light py-2 px-3"} role={"button"} onClick={() => setCurrentStep(1)}>上一步</div>
    }

    const getRightButton = () => {
        if (currentStep === 1) {
            return <div className={"one-dream-button py-2 px-3"} role={"button"}
                        onClick={() => setCurrentStep(2)}>下一步</div>
        }

        return <div className={"one-dream-button py-2 px-3"} role={"button"}
                    onClick={() => handleCloseGuideModal()}>完成導覽</div>
    }

    return <Modal contentClassName={"rounded-modal-border text-center"}
                  show={showGettingStartedGuideModal}
                  onHide={() => handleCloseGuideModal()}
                  size={getSize()}
    >

        <Modal.Header closeButton className={"border-bottom-0"}>
            <Modal.Title className={"ms-auto"}>新手導覽</Modal.Title>
        </Modal.Header>
        <ModalBody>
            <div className={"fs-6"}>恭喜您註冊成員本平台會員, 請依序領取獎勵:</div>
            <div className={"mt-5"}>
                {
                    getGuideImage()
                }
            </div>
        </ModalBody>
        <ModalFooter>
            <div className={"d-flex justify-content-end"}>
                {getLeftButton()}
                {getRightButton()}
            </div>

        </ModalFooter>
    </Modal>
}
