import SliderNextArrow from "../Components/SliderNextArrow";
import SliderPrevArrow from "../Components/SliderPrevArrow";

export default function SliderTwoCol() {
    return {
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        adaptiveHeight: true,
        rows: 1,
        prevArrow: <SliderPrevArrow/>,
        nextArrow: <SliderNextArrow/>,
        mobileFirst: true,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: false,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]

    }
}
