import {useNavigate} from "react-router-dom";

export default function DesktopSearchBox({search, setSearch}) {
    const navigate = useNavigate()
    return (
        <div style={{width: "130px"}}>
            <form
                onSubmit={(event) => {
                    event.preventDefault()
                    navigate(`/search-result?search=${search}`)
                }}
                className="d-flex align-items-center bg-transparent overflow-hidden border-radius-five border border-dark">

                <input type="text" placeholder="Search..."
                       className="border-0 form-control form-control-sm bg-transparent"
                       onChange={(event) => setSearch(event.target.value)}
                       value={search}
                />
                <button className="btn text-primary btn-sm px-3"><i
                    className="icofont-search-1"></i></button>
            </form>
        </div>
    )
}