import React, {useState} from "react";
import SendVerificationViaEmail from "../../Components/SendVerificationViaEmail";
import NoticeModal from "../../Components/NoticeModal";
import {useSendUserRegistrationVerificationCode} from "../../APIs/apis";
import toast from "react-hot-toast";
import {isValidEmailPattern} from "../../Helpers";
import {useIntl} from "react-intl";

export default function EnterEmailSection({email, setEmail, setStep}) {
    const [showNoticeModal, setShowNoticeModal] = useState(false)
    const [isEmailInputValid, setIsEmailInputValid] = useState(true)
    const [shouldCountDown, setShouldCountDown] = useState(false)
    const [count, setCount] = useState(60)
    const intl = useIntl()

    const handleSendVerificationCodeError = (err) => {
        const errorData = err.response.data
        if (errorData.message === 'The email has already been taken.') {
            setIsEmailInputValid(false)
            toast.error(intl.formatMessage({id: 'email_already_taken'}))
        }

        if (errorData.message === 'The email needs to wait some time before sending verification code.') {
            setShouldCountDown(true)
            setCount(errorData.to_be_waited_seconds)
            toast.error(intl.formatMessage({id: 'send_verification_code_too_frequently'}))
        }
    }

    const handleSendVerificationCodeSuccess = () => {
        setIsEmailInputValid(true)
        setShowNoticeModal(true)
    }

    const sendVerificationCode = useSendUserRegistrationVerificationCode({
        onError: handleSendVerificationCodeError,
        onSuccess: handleSendVerificationCodeSuccess
    })

    const handleSendEmailButtonClick = (e) => {
        e.preventDefault()
        sendVerificationCode.mutate(email)
    }

    const handleEmailInputChange = (e) => {
        setIsEmailInputValid(true)
        setEmail(e)
    }

    const handleNoticeModalClicked = () => {
        setStep(2)
    }

    const isSendButtonEnabled = () => {
        return !shouldCountDown && isValidEmailPattern(email) && isEmailInputValid
    }

    return (
        <>
            <SendVerificationViaEmail
                title={intl.formatMessage({id: 'app.register.enter_email.title'})}
                inputDescription={intl.formatMessage({id: 'app.register.enter_email.description'})}
                email={email}
                setEmail={handleEmailInputChange}
                isEmailInputValid={isEmailInputValid}
                handleSendEmailButtonClick={handleSendEmailButtonClick}
                shouldCountDown={shouldCountDown}
                setShouldCountDown={setShouldCountDown}
                count={count}
                setCount={setCount}
                isSendButtonEnabled={isSendButtonEnabled}
                isSendButtonLoading={sendVerificationCode.isLoading}
            />
            <NoticeModal show={showNoticeModal}
                         title={intl.formatMessage({id: 'app.register.enter_email.notice_modal.title'})}
                         handleClick={handleNoticeModalClicked}/>
        </>
    )
}