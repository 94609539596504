import React from "react";
import {AiOutlineArrowRight} from "react-icons/ai";
import {Link} from "react-router-dom";
import MoreItem from "./MoreItem";

const More = () => {
    return (
        <div className={"container px-4"}>
            <div className={"fs-1 fw-bold mb-4 mt-4"}>更多</div>
            <MoreItem itemName={"密碼"} linkTarget={"/reset-password"}/>
            <hr/>
            <MoreItem itemName={"推薦碼"} linkTarget={"/referral-code"}/>
            <hr/>
            {/*<MoreItem itemName={"兌換獎勵"} linkTarget={"/claim-nfts"}/>*/}
            {/*<hr/>*/}
            <div className={"fs-5 mt-5 mb-4 fw-bolder"}>其他</div>
            <div className={"position-relative"}>
                <div className={"d-flex justify-content-between"}>
                    <div className={"fs-3"}>使用者條款暨隱私權政策</div>
                    <AiOutlineArrowRight className={"fs-3"}/>
                </div>
                <Link to={'/privacy-and-terms-of-use'} className={"stretched-link"}/>
            </div>
            <hr/>
        </div>
    )
}

export default More;