import React, {useState} from "react";
import {handleFormChangeTemplate} from "../../Helpers";
import EnterEmailSection from "./EnterEmailSection";
import CompleteRegistration from "./CompleteRegistration";
import RegistrationFormSection from "./RegistrationFormSection";

export default function Registration() {
    const [step, setStep] = useState(1)
    const [form, setForm] = useState({email: "", verification_code: "", password: ""})
    const handleFormChange = (e) => {
        handleFormChangeTemplate(e, setForm)
    }

    const getSection = () => {
        switch (step) {
            case 1:
                return <EnterEmailSection email={form.email} setStep={setStep} setEmail={handleFormChange}/>
            case 2:
                return <RegistrationFormSection form={form} setStep={setStep} handleChange={handleFormChange}/>
            case 3:
                return <CompleteRegistration email={form.email} setStep={setStep}/>
            default:
                return <EnterEmailSection email={form.email} setStep={setStep} setEmail={handleFormChange}/>
        }
    }

    return (
        <div className={"container p-4"}>
            <h2 className={"mb-5"}>註冊</h2>
            {getSection()}
        </div>
    )
}