import {Link} from "react-router-dom";
import {useMyNfts, useRefreshBalances, useUserSelf} from "../APIs/apis";
import Loading from "../Components/Loading";
import {useQueryClient} from "@tanstack/react-query";
import {getBlockchainLogo, getRoundedNftPrice} from "../Helpers";
import NftImage from "../Components/NftImage";

export default function Wallet() {
    const userData = useUserSelf()
    const refreshBalances = useRefreshBalances()
    const queryClient = useQueryClient()
    const myNfts = useMyNfts()

    const handleRefreshBalances = async () => {
        await refreshBalances.mutateAsync()
        queryClient.invalidateQueries('user-self')
    }

    return (
        userData.isLoading ? <Loading/> : userData.isSuccess &&
            <main>
                <div className="container">
                    <h3 className="mb-3 mt-4">我的錢包</h3>
                    <div className={"border border-radius-five border-2 border-dark py-3 px-2"}>
                        {
                            userData.data.balances.map((balance, index) => {
                                return (
                                    <div key={index}>
                                        <div>
                                            <div className="d-flex justify-content-between bg-transparent">
                                                <div className="d-flex align-items-center gap-2">
                                                    <img className="img-fluid" style={{height: "18px"}}
                                                         src={getBlockchainLogo(balance.chain_id)}
                                                         alt={balance.blockchain}/>
                                                    <div>{balance.blockchain}</div>
                                                </div>
                                                <div
                                                    className="fw-bold">{getRoundedNftPrice(balance.balance)} {balance.currency}</div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </div>
                                )
                            })
                        }
                        {
                            refreshBalances.isLoading ? <Loading/> : userData.data.balances.length !== 0 &&
                                <div className={"d-flex justify-content-end"} onClick={() => handleRefreshBalances()}
                                     role={"button"}>更新餘額</div>
                        }
                    </div>
                    {
                        myNfts.isSuccess &&
                        <>
                            <h3 className="mt-5">我的 NFTs</h3>
                            <div className="d-flex gap-2">
                                <div className="col-2 border border-dark border-bottom-0 text-center">
                                    <span className="d-block mt-2 mb-2">收藏({myNfts?.data.length})</span>
                                </div>
                            </div>
                            <div className="row row-cols-2 row-cols-md-4 mt-3 osahan-item-list">
                                {
                                    myNfts?.data.map(nft => {
                                        return (

                                            <div className="card bg-transparent border-0 osahan-card-item" key={nft.id}>
                                                <Link to={`/nft?id=${nft.id}`}
                                                      className={"stretched-link"}></Link>
                                                <NftImage imgSrc={nft.nft_image_url}
                                                          imgAlt={nft.nft_name + '_image'}
                                                />

                                                <p className="">{nft.nft_name}</p>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>
                    }
                </div>
            </main>
    )
}
