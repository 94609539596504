import {useEffect} from "react";
import {useRecoilState} from "recoil";
import userAtom from "../States/user";
import {isLoggedIn} from "../Helpers";
import {useUserSelf} from "../APIs/apis";

export default function SetUserData() {
    const [, setUserData] = useRecoilState(userAtom)

    const user = useUserSelf({
        enabled: !!isLoggedIn()
    })

    useEffect(() => {
        setUserData(() => user.data)
    }, [user])
}