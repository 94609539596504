import enterReferralCodeImg from "./Assets/enter-referral-code-img.png"
import {useReferralCode} from "../../APIs/apis";
import {useState} from "react";
import toast from "react-hot-toast";
import GainClaimableNft from "../../Components/GainClaimableNft";
import {useQueryClient} from "@tanstack/react-query";
import {Spinner} from "react-bootstrap";

export default function EnterReferralCodeSection({userData}) {
    const enteredReferralCode = userData?.entered_referral_code
    const [formData, setFormData] = useState({referralCode: ""})
    const [showGainNftModal, setShowGainNftModal] = useState(false)
    const enterReferralCode = useReferralCode()
    const queryClient = useQueryClient()

    const handleEnterReferralCodeError = () => {
        toast.error('無效的推薦碼, 請重新確認後輸入')
    }

    const handleEnterReferralCodeSuccess = () => {
        setShowGainNftModal(true)
        queryClient.invalidateQueries('user-self')
    }

    const handleReferralFormSubmit = (event) => {
        event.preventDefault()
        enterReferralCode.mutate(formData.referralCode, {
            onError: handleEnterReferralCodeError,
            onSuccess: handleEnterReferralCodeSuccess
        })
    }

    const handleReferralFormChange = (event) => {
        setFormData((prevState) => {
            return {
                ...prevState, referralCode: event.target.value
            }
        })
    }

    const getReferralInputStyle = () => {
        if (userData?.entered_referral_code === true) {
            return {backgroundColor: '#F0F0F0'}
        }

        return {}
    }

    return (
        <>
            <div className={"text-center"}>
                <img src={enterReferralCodeImg} alt={'enter-referral-code-img'}
                     style={{width: "50px"}}/>
                <div className={"mt-4 fs-1 fw-bold"}>輸入抽 NFT !</div>
                <form className={"mt-4"} onSubmit={handleReferralFormSubmit}>
                    <input className={"w-100 border-radius-five border border-2 fs-5 px-3 py-2"}
                           type={"text"}
                           placeholder={"輸入好友推薦碼"}
                           disabled={enteredReferralCode}
                           style={getReferralInputStyle()}
                           required={true}
                           value={formData.referralCode}
                           onChange={handleReferralFormChange}
                    />
                    <button type={"submit"}
                            className={"mt-3 form-control fs-5" + (enteredReferralCode ? " one-dream-button-disabled" : " one-dream-button")}
                            disabled={enteredReferralCode}
                    >{
                        enterReferralCode.isLoading
                            ? <Spinner size={'sm'}/>
                            : enteredReferralCode
                                ? '您已抽過'
                                : '確定'
                    }
                    </button>
                </form>
            </div>
            <ol className={"mt-4 px-3 fs-6"}>
                <li>
                    輸入「好友推薦碼」，您將獲得抽取NFT的機會。
                </li>
                <li>
                    輸入「好友推薦碼」的次數限為一次。
                </li>
                <li>
                    OneDream 保有活動之解釋、修改、調整、終止等相關權利。
                </li>
            </ol>
            <GainClaimableNft show={showGainNftModal} onHide={() => setShowGainNftModal(false)}/>
        </>
    )
}