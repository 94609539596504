import {useSearchParams} from "react-router-dom";
import {usePaginatedNftGroups} from "../APIs/apis";
import Loading from "../Components/Loading";
import NftGroupCard from "../Components/NftGroupCard";
import LoadMorePages from "../Components/LoadMorePages";

export default function SearchResult() {
    const [searchParams] = useSearchParams()
    const search = searchParams.get("search")
    const paginatedNftGroups = usePaginatedNftGroups({}, [], [], 10, 'created_at', 'desc', '', search)

    return (
        <div className={"m-4"}>
            <h4 className={"mb-4"}>{
                search === '' ? '無搜尋條件' : `"${search}" 的搜尋結果`
            }</h4>
            {
                paginatedNftGroups.isLoading ? <Loading/> : paginatedNftGroups.isSuccess &&
                    <div>
                        <p className={"mb-2 text-muted"}>NFTs</p>
                        <div className={"row"}>
                            {
                                paginatedNftGroups.data.pages.map((page) => {
                                    return page.data.map((nftGroup, index) => {
                                        return <NftGroupCard isSliderItem={false} nftGroup={nftGroup} key={index}/>
                                    })
                                })
                            }
                            <LoadMorePages pagination={paginatedNftGroups} loadMoreText={"看更多 NFTs"}/>
                        </div>

                    </div>
            }
        </div>
    )
}