import React, {useEffect, useState} from "react";
import OneDreamButton from "./OneDreamButton";
import NoticeModal from "./NoticeModal";
import toast from "react-hot-toast";

export default function SendVerificationCodeEmailButton({email, sendVerificationCode}) {
    const defaultCountDownSeconds = 60
    const [showNoticeModal, setShowNoticeModal] = useState(false)
    const [sendVerificationCodeEmailEnabled, setSendVerificationCodeEmailEnabled] = useState(false)
    const [count, setCount] = useState(defaultCountDownSeconds)
    const getButtonText = () => {
        const defaultText = '重新寄送驗證信'
        if (sendVerificationCodeEmailEnabled) {
            return defaultText
        }
        return `${defaultText} (${count}s)`
    }

    const handleSendVerificationCodeError = (err) => {
        let timerId;
        const errorData = err.response.data

        if (errorData.message === 'The email has already been taken.') {
            toast.error('此 Email 已被使用, 將導向 Email 輸入頁。')
            timerId = setTimeout(() => {
                window.location.reload()
            }, 2000)
        }

        if (errorData.message === 'The email needs to wait some time to send the verification code.') {
            setSendVerificationCodeEmailEnabled(false)
            setCount(errorData.to_be_waited_seconds)
            toast.error('發送 Email 頻率太過頻繁, 請耐心等候幾秒後再次發送。')
        }

        return () => clearTimeout(timerId)
    }

    const handleSendVerificationCodeSuccess = () => {
        setShowNoticeModal(true)
        setSendVerificationCodeEmailEnabled(false)
    }

    useEffect(() => {
        let timerId;
        if (!sendVerificationCodeEmailEnabled) {
            timerId = setInterval(() => {
                setCount(count => count - 1)
            }, 1000)
        }

        if (count === 0) {
            setSendVerificationCodeEmailEnabled(true)
            setCount(defaultCountDownSeconds)
            clearInterval(timerId)
        }

        return () => clearInterval(timerId)
    }, [sendVerificationCodeEmailEnabled, count])

    const handleClick = (e) => {
        e.preventDefault()
        sendVerificationCode.mutate(email, {
            onError: handleSendVerificationCodeError,
            onSuccess: handleSendVerificationCodeSuccess
        })
    }

    return (
        <>
            <OneDreamButton enabled={sendVerificationCodeEmailEnabled} onClick={handleClick} text={getButtonText()} isLoading={sendVerificationCode.isLoading}/>
            <NoticeModal show={showNoticeModal} title={"驗證信已寄出。"} handleClick={() => setShowNoticeModal(false)}/>
        </>
    )
}