import {IoMdCopy} from "react-icons/io";
import myReferralCodeImg from './Assets/my-referral-code-img.png'
import {Spinner} from "react-bootstrap";

export default function ReferralCodeSection({referralCode, showCopied, setShowCopied}) {
    const handleClickCopy = () => {
        navigator.clipboard.writeText(referralCode)
        setShowCopied(true)
        setTimeout(() => setShowCopied(false), 1500)
    }

    return (
        <>
            <div className={"text-center"}>
                <div>
                    <img src={myReferralCodeImg} alt={'my-referral-code-img'} className={"img-fluid"}
                         style={{width: '50px'}}/>
                </div>
                <div className={"mt-4 fs-6"}>
                    分享您的專屬推薦碼給好友，待好友成功輸入您的推薦碼後，您將獲得抽取NFT的機會！
                </div>
                <div className={"mt-4 fs-4 fw-bolder"}>我的推薦碼</div>
                <div
                    className={"mt-3 d-flex justify-content-between align-items-center bg-gray py-2 px-4 border-radius-five"}>
                    <div className={"fw-bold fs-3"}>{referralCode ? referralCode : <Spinner size={'sm'}/>}</div>
                    <div className={"position-relative"} role={"button"}
                         onClick={() => handleClickCopy()}
                    >
                        {
                            showCopied &&
                            <div className={"position-absolute bottom-100"}
                                 style={{width: "40px"}}>已複製</div>
                        }
                        <IoMdCopy className={"fs-1 text-muted"}/>
                    </div>
                </div>
            </div>
            <ol className={"mt-4 fs-6"}>
                <li>分享您的「專屬推薦碼」給好友，對方輸入後、您將獲得抽取NFT的機會。</li>
                <li>分享越多，抽取次數越多，抽取次數無上限。</li>
                <li>OneDream 保有活動之解釋、修改、調整、終止等相關權利。</li>
            </ol>
        </>
    )
}