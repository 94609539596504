import {useEffect} from "react";
import {isLoggedIn} from "../Helpers";
import {useLogout} from "../APIs/apis";

export default function ReloadPageUponAccountChanged() {
    const logout = useLogout()
    useEffect(() => {
        if (typeof window.ethereum !== 'undefined') {
            window.ethereum.on('accountsChanged', async () => {
                if (isLoggedIn()) {
                    await logout.mutateAsync()
                }
                window.location.reload()
            })
        }
    }, [window.ethereum])
}