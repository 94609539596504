export default function OrganizationIntro() {
    return (
        <div className={"container"}>
            <div className={"fw-bold fs-3 mb-4"}>公司簡介</div>
            <div className={"fs-6"}>
                <p>OneDream原由影劇平台起家，現在，我們致力於打造一個專為影劇和動漫而生的NFT平台。</p>
                <p>
                    在OneDream，將會推出各種任務活動，用戶們可以藉由完成任務的方式來抽取站內的NFT，讓每個人都有機會獲得自己所喜愛的NFT。 <br/>
                    因我們的經營理念，是讓來到OneDream的使用者們不用花費大量金錢、時間或是精力來煩惱購買流程和市場波動，就能夠更輕鬆地獲取NFT。
                </p>

                <p>
                    在這條路上，我們不斷地創新和研究，全新的OneDream將帶給劇迷和動漫迷們前所未有的體驗！
                </p>
                <p>
                    OneDream──用嶄新的角度，探索更多NFT的可能性；並且，透過獨特的模式，打造一個公平、開放且充滿樂趣的夢想之地。
                </p>
            </div>
        </div>
    )
}