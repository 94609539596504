import {Modal} from "react-bootstrap";
import {useNavigate} from "react-router-dom";
import _ from "lodash"

export default function MintedNftVersions(props) {
    const navigate = useNavigate()
    const handleRouteNft = (nftId) => {
        props.onHide()
        navigate(`/nft?id=${nftId}`)
    }
    return (
        <Modal show={props.show} onHide={props.onHide} scrollable={true}>
            <Modal.Header closeButton>
                <Modal.Title>選擇版本</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className={"bg-light row p-2 fw-bold"}>
                        <div className={"col-3"}>
                            版本
                        </div>
                        <div className={"col-3"}>
                            價格
                        </div>
                        <div className={"col-6"}>
                            持有者
                        </div>
                    </div>
                    {
                        props.mintedNfts.map((nft, index) => {
                            return (
                                <div key={index}>
                                    <div className={"row p-2 align-items-center"}>
                                        <div className={"col-3"}>
                                            {nft.nft_version}
                                        </div>
                                        <div className={"col-3"}>
                                            {
                                                nft.nft_price
                                                    ? `${nft.currency} ${Number(nft.nft_price).toFixed(4)}`
                                                    : '非出售中'
                                            }
                                        </div>
                                        <div className={"col-4"}>
                                            {_.truncate(nft?.owner.wallet_address, {
                                                length: 12
                                            })}
                                        </div>
                                        <div className={"col-2"}>
                                            <i className="bi bi-chevron-compact-right fs-5" role={"button"}
                                               onClick={() => handleRouteNft(nft.id)}
                                            >
                                            </i>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}
