import React, {useState} from "react";
import EnterEmailSection from "../ForgetPassword/EnterEmailSection";
import ResetPasswordFormSection from "./ResetPasswordFormSection";
import {handleFormChangeTemplate} from "../../Helpers";
import CompletePasswordReset from "./CompletePasswordReset";

export default function ForgetPassword() {
    const [step, setStep] = useState(1)
    const [form, setForm] = useState({email: "", verification_code: "", password: ""})
    const handleFormChange = (e) => {
        handleFormChangeTemplate(e, setForm)
    }
    const getSection = () => {
        switch (step) {
            case 1:
                return <EnterEmailSection email={form.email} setStep={setStep} setEmail={handleFormChange}/>
            case 2:
                return <ResetPasswordFormSection form={form} setStep={setStep} handleChange={handleFormChange}/>
            case 3:
                return <CompletePasswordReset email={form.email} setStep={setStep}/>
            default:
                return <EnterEmailSection email={form.email} setStep={setStep} setEmail={handleFormChange}/>
        }
    }

    return (
        <div className={"container p-4"}>
            <h2 className={"mb-5"}>忘記密碼</h2>
            {getSection()}
        </div>
    )
}