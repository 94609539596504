import React from "react";
import SeriesCard from "../Components/SeriesCard";
import Slider from "react-slick";
import SliderOneCol from "../Settings/SliderOneCol";
import SliderTwoCol from "../Settings/SliderTwoCol";
import ActorRoundedCard from "../Components/ActorRoundedCard";
import {useActors, useSeries} from "../APIs/apis";
import Loading from "../Components/Loading";

const Explore = () => {
    const actors = useActors()
    const series = useSeries()

    return (
        <section className="py-1">
            <div className="container py-1">
                <div className="mt-3 mb-3">
                    <div className="d-flex align-items-center justify-content-between mb-2 pb-1">
                        <div className="h4 m-0 fw-bold">戲劇</div>
                        <div><a href="series" className="btn btn-outline-primary btn-sm px-3">View All</a>
                        </div>
                    </div>
                    {
                        series.isLoading ? <Loading/> : series.isSuccess &&
                            <Slider {...SliderOneCol()}>
                                {series.data.map(function (series, index) {
                                    return <SeriesCard series={series} key={index} isSliderItems={true}/>
                                })}
                            </Slider>
                    }
                </div>
                <div className="mt-5 mb-3">
                    <div className="d-flex align-items-center justify-content-between mb-2 pb-1">
                        <div className="h4 m-0 fw-bold">演員</div>
                        <div><a href="actors" className="btn btn-outline-primary btn-sm px-3">View All</a>
                        </div>
                    </div>
                    {
                        actors.isLoading ? <Loading/> : actors.isSuccess &&
                            <Slider {...SliderTwoCol()}>
                                {actors.data.map(function (actor, index) {
                                    return <ActorRoundedCard actor={actor} key={index} isSliderItems={true}/>
                                })}
                            </Slider>
                    }
                </div>
            </div>
        </section>
    )
}

export default Explore