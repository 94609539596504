import {Outlet} from "react-router-dom";
import {useState, useEffect} from "react";
import {useRefreshToken} from "../APIs/apis";
import {useRecoilState} from "recoil";
import authAtom from "../States/auth";
import persistentAtom from "../States/persistent";

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const [auth] = useRecoilState(authAtom)
    const [persistent] = useRecoilState(persistentAtom)

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try {
                await refresh()
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }

        !auth?.accessToken && persistent ? verifyRefreshToken() : setIsLoading(false);
    }, [])

    return (
        <>
            {!persistent
                ? <Outlet/>
                : isLoading
                    ?
                    <div className="text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : <Outlet/>
            }
        </>
    )
}

export default PersistLogin