import React from "react";
import {Spinner} from "react-bootstrap";

export default function OneDreamButton({enabled, text, onClick, className, isLoading = false}) {
    const buttonClass = () => {
        let defaultClass = 'form-control'

        if (enabled) {
            return defaultClass + ' one-dream-button'
        }

        return defaultClass + ' one-dream-button-disabled'
    }

    return (
        <button className={buttonClass() + " " + className}
                disabled={!enabled}
                onClick={onClick}
        >
            {
                isLoading ? <Spinner animation="border" size={"sm"}/> : text
            }
        </button>
    )
}