import React, {useEffect, useState} from "react";
import {HiExclamationTriangle} from "react-icons/hi2";
import {Link, useLocation, useNavigate} from "react-router-dom";
import EmailInput from "../../Components/EmailInput";
import LoginPasswordInput from "../../Components/LoginPasswordInput";
import RememberAccountCheckBox from "../../RememberAccountCheckBox";
import OneDreamButton from "../../Components/OneDreamButton";
import toast from "react-hot-toast";
import {handleFormChangeTemplate} from "../../Helpers";
import {useLoginWithEmail, usePrecognitionCheckEmailLoginInput} from "../../APIs/apis";
import {useIntl} from "react-intl";

export default function Login() {
    const [form, setForm] = useState({email: localStorage.getItem("email") || "", password: "", rememberAccount: false})
    const [credentialError, setCredentialError] = useState(false)
    const [isEmailInputFormatValid, setIsEmailInputFormatValid] = useState(false)
    const [isEmailInputValid, setIsEmailInputValid] = useState(true)
    const [passwordInputValid, setPasswordInputValid] = useState(false)
    const [buttonEnabled, setButtonEnabled] = useState(false)
    const location = useLocation()
    const navigate = useNavigate()
    const intl = useIntl()
    const login = useLoginWithEmail()

    const checkEmailValidity = usePrecognitionCheckEmailLoginInput("email")

    const handleLoginSuccess = (data) => {
        if (form.rememberAccount) {
            localStorage.setItem("email", form.email)
        } else {
            localStorage.removeItem("email")
        }

        const from = location.state?.from?.pathname + location.state?.from?.search;

        if (from) {
            navigate(from)
        } else {
            navigate("/")
        }
    }

    const handleLoginError = (error) => {
        const errorRes = error.response
        if (errorRes.status === 401) {
            setCredentialError(true)
            setButtonEnabled(false)
        }
    }

    const handleEmailInvalid = (error) => {
        const errorData = error.response.data
        if (errorData.message === 'The email has not been registered.') {
            setIsEmailInputValid(false)
            toast.error(intl.formatMessage({id: 'email_not_registered'}))
        }
    }

    const handleFormChange = (e) => {
        handleFormChangeTemplate(e, setForm)
        if (e.target.name === 'email') {
            setIsEmailInputValid(true)
        }

        setCredentialError(false)
    }

    const handleEmailInputBlur = (e) => {
        if (isEmailInputFormatValid) {
            checkEmailValidity.mutate(form, {
                onError: handleEmailInvalid
            })
        }
    }

    const handleOnFormSubmit = (e) => {
        e.preventDefault()

        login.mutate(form, {
            onSuccess: handleLoginSuccess,
            onError: handleLoginError
        })
    }

    useEffect(() => {
        setButtonEnabled(isEmailInputFormatValid && passwordInputValid)
    }, [isEmailInputFormatValid, passwordInputValid, form])

    return (
        <div className={"container p-4"}>
            <h2>登入</h2>
            <form className={"mt-4"} onSubmit={handleOnFormSubmit}>
                <label className={"mb-1"} htmlFor={"email"}>Email</label>
                <EmailInput id={"email"} name={"email"} value={form.email} handleChange={handleFormChange}
                            setEmailInputFormatValid={setIsEmailInputFormatValid} handleOnBlur={handleEmailInputBlur}
                            isEmailInputValid={isEmailInputValid}/>
                <label className={"mb-1 mt-4"} htmlFor={"password"}>密碼</label>
                <LoginPasswordInput name={"password"} id={"password"} handleChange={handleFormChange}
                                    value={form.password}
                                    placeHolder={'請輸入密碼'} setPasswordInputValid={setPasswordInputValid}/>
                <div className={"d-flex justify-content-between align-items-end"}>
                    <RememberAccountCheckBox id={"rememberAccount"} name={"rememberAccount"}
                                             checked={form.rememberAccount} value={form.rememberAccount}
                                             handleChange={handleFormChange}/>
                    <Link to={"/forget-password"}>忘記密碼？</Link>
                </div>
                <div className={"mt-4"}>
                    <OneDreamButton enabled={buttonEnabled} text={"登入"} isLoading={login.isLoading}/>
                </div>
                <div className={"fs-6 text-danger mt-3"}>
                    {
                        credentialError ?
                            <div className={"d-flex gap-1 justify-content-center align-items-center"}
                                 style={{height: "25px"}}>
                                <HiExclamationTriangle className={"fs-4"}/>
                                <div>帳號或密碼不正確, 請再檢查一下。</div>
                            </div> :
                            <div style={{height: "25px"}}></div>
                    }
                </div>
            </form>
            <div className={"text-center mt-5"}>
                <p>您還不是會員嗎？ 立即免費註冊</p>
            </div>
            <Link to={"/registration"}
                  className={"form-control border border-2 border-success fw-bold text-center"}>註冊</Link>
            <div className={"text-center mt-5"}>
                <p>註冊及登入代表您同意我們的 <Link to={"/privacy-and-terms-of-use"}>使用者條款暨隱私權政策</Link></p>
            </div>
        </div>
    )
}