import React from "react";
import {AiOutlineArrowRight} from "react-icons/ai";
import {Link} from "react-router-dom";

export default function MoreItem({itemName, linkTarget}) {
    return (
        <div className={"position-relative"}>
            <div className={"d-flex justify-content-between"}>
                <div className={"fs-3"}>{itemName}</div>
                <AiOutlineArrowRight className={"fs-3"}/>
            </div>
            <Link to={linkTarget} className={"stretched-link"}/>
        </div>
    )
}