export default (props) => {
    const {onClick} = props
    return (
        <i
            className={"bi bi-chevron-right live-right-arrow"}
            onClick={onClick}
        >
        </i>
    )
}
