import Nav from "./Nav";
import {Outlet} from "react-router-dom";
import {Toaster} from "react-hot-toast";
import Footer from "./Footer/index";
import ReloadPageUponAccountChanged from "./ReloadPageUponAccountChanged";
import SetUserData from "./SetUserData";
import GettingStartedGuide from "./GettingStartedGuide/index";

export function Layout() {
    return (
        <div className={"d-flex flex-column vh-100"}>
            <Nav/>
            <Toaster/>
            <div className={"flex-grow-1"}>
                <Outlet/>
            </div>
            <div className={"mt-auto"}>
                <Footer/>
            </div>
            <ReloadPageUponAccountChanged/>
            <SetUserData/>
            <GettingStartedGuide/>
        </div>
    )
}