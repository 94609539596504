import _ from 'lodash'
import {getRoundedNftPrice} from "../Helpers";

const NftHistory = (props) => {
    return (
        <>
            {
                props?.page?.data.map((record, index) => {
                    return (
                        <div className="mb-3" key={index}>
                            <div
                                className="d-flex justify-content-between rounded align-items-center mb-1">
                                <div className="fw-bold">{_.truncate(record.to_address, {
                                    length: 12
                                })}</div>
                                <div className="fw-bold">{`${getRoundedNftPrice(record.nft_price)} ${record.currency}`} </div>
                            </div>
                            <div className="mb-1">{record.transaction_type_text} {record.nft_version}</div>
                            <div>{record.created_at}</div>
                        </div>
                    )
                })
            }
        </>
    )
}

export default NftHistory