import React, {useEffect, useState} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
import {useTradeMarketTrxRecords} from "../APIs/apis";
import Loading from "../Components/Loading";
import {Link} from "react-router-dom";
import NftGroupCard from "../Components/NftGroupCard";
import {usePaginatedNftGroups} from "../APIs/apis";
import LoadMorePages from "../Components/LoadMorePages";
import {useInView} from "react-intersection-observer";
import {getRoundedNftPrice} from "../Helpers";
import NftImage from "../Components/NftImage";

export default function MarketPlace() {
    const tradeMarketTrxRecords = useTradeMarketTrxRecords()
    const [sortNftGroup, setSortNftGroup] = useState('created_at')
    const [sortNftGruopDirection, setSortNftGroupDirection] = useState('desc')
    const {ref, inView} = useInView()

    const nftGroups = usePaginatedNftGroups({}, [], [], 10, sortNftGroup, sortNftGruopDirection, 1)

    useEffect(() => {
        if (inView && nftGroups.hasNextPage) {
            nftGroups.fetchNextPage()
        }
    }, [inView, nftGroups])

    const handleSortOnSelect = (eventKey) => {
        switch (eventKey) {
            case 'created_at_desc':
                setSortNftGroup(() => 'created_at')
                setSortNftGroupDirection(() => 'desc')
                break;
            case 'price_desc':
                setSortNftGroup(() => 'min_price')
                setSortNftGroupDirection(() => 'desc')
                break;
            case 'price_asc':
                setSortNftGroup(() => 'min_price')
                setSortNftGroupDirection(() => 'asc')
                break;
            default:
                setSortNftGroup(() => 'created_at')
                setSortNftGroupDirection(() => 'desc')

        }
    }

    return (
        <div className={"container"}>
            <h4 className={"mt-4"}>交易市場</h4>

            <ul className="nav auther-tabs mb-0 gap-3 border-bottom px-4 py-3 justify-content-center"
                id="pills-tab"
                role="tablist">
                <li className="nav-item" role="presentation">
                    <button className="nav-link active" id="pills-on-sale-tab" data-bs-toggle="pill"
                            data-bs-target="#on-sale-nft-groups" type="button" role="tab"
                            aria-controls="pills-on-sale"
                            aria-selected="true">目前銷售中
                    </button>
                </li>
                <li className="nav-item" role="presentation">
                    <button className="nav-link" id="pills-sold-history-tab" data-bs-toggle="pill"
                            data-bs-target="#sold-history" type="button" role="tab"
                            aria-controls="pills-sold-history"
                            aria-selected="false">最高成交紀錄
                    </button>
                </li>
            </ul>

            <div className="tab-content" id="pills-tabContent">
                <div className="tab-pane fade show active" id="on-sale-nft-groups" role="tabpanel"
                     aria-labelledby="pills-on-sale-tab">
                    <Nav className={"justify-content-end"} onSelect={handleSortOnSelect}>
                        <NavDropdown title={<span className={"text-dark"}>排序</span>} id="nav-dropdown"
                                     className={"me-4"}>
                            <NavDropdown.Item eventKey="created_at_desc"
                                              active={sortNftGroup === 'created_at' && sortNftGruopDirection === 'desc'}>最近上架</NavDropdown.Item>
                            <NavDropdown.Item eventKey="price_asc"
                                              active={sortNftGroup === 'min_price' && sortNftGruopDirection === 'asc'}>價格低至高</NavDropdown.Item>
                            <NavDropdown.Item eventKey="price_desc"
                                              active={sortNftGroup === 'min_price' && sortNftGruopDirection === 'desc'}>價格高至低</NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
                    <div className="row m-1">
                        {
                            nftGroups.isLoading ? <Loading/> : nftGroups.isSuccess &&
                                nftGroups.data.pages.map((page) => {
                                    return page.data.map((nftGroup, index) => {
                                        return <NftGroupCard nftGroup={nftGroup}
                                                             isSliderItem={false}
                                                             key={index}/>
                                    })
                                })
                        }
                        {
                            nftGroups.isSuccess &&
                            <LoadMorePages pagination={nftGroups} loadMoreText={'查看更多 NFT'} reference={ref}/>
                        }
                    </div>
                </div>
                {
                    tradeMarketTrxRecords.isLoading ? <Loading/> : tradeMarketTrxRecords.isSuccess &&
                        <div className="tab-pane fade" id="sold-history" role="tabpanel"
                             aria-labelledby="pills-sold-history-tab">
                            <div className={"row"}>
                                {
                                    tradeMarketTrxRecords.data.map((record, index) => {
                                        return (
                                            <div
                                                className="col-12 col-md-6 col-lg-4 col-xl-3 d-flex gap-2 justify-content-start align-items-center mb-2 mt-2"
                                                key={index}
                                            >
                                                <div>
                                                    {index + 1}
                                                </div>
                                                <div
                                                    className={"position-relative"}
                                                >
                                                    <div style={{width: '100px'}}>
                                                        <NftImage imgSrc={record.nft_image_url}
                                                                  imgAlt={record.nft_name + "_image"}/>
                                                    </div>
                                                    <Link to={`/nft?id=${record.nft.id}`} className={"stretched-link"}/>
                                                </div>
                                                <div>
                                                    <div className={"mb-2"}>{record.nft_name}</div>
                                                    <div className={"d-flex gap-2 align-items-center mb-2"}
                                                         style={{position: "relative"}}
                                                    >
                                                        {
                                                            record.series &&
                                                            <>
                                                                <div style={{width: "25px", height: "25px"}}>
                                                                    <img alt={record.series.series_name}
                                                                         src={record.series.series_image_url}
                                                                         className={"img-fluid w-100 h-100 rounded-circle"}
                                                                    />
                                                                </div>
                                                                <div>
                                                                    {record.series.series_name}
                                                                </div>
                                                                <Link to={`/single-series?id=${record.series.id}`}
                                                                      className={"stretched-link"}/>
                                                            </>
                                                        }
                                                    </div>
                                                    <div>
                                                        <span
                                                            className={"fw-bold"}>{getRoundedNftPrice(record.sold_price)} {record.currency}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    )
}
