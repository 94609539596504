import React from "react";
import ActorCard from "../Components/ActorCard";
import {useSearchParams} from "react-router-dom";
import ReadMore from "../Components/ReadMore";
import NftGroupCard from "../Components/NftGroupCard";
import {useActors, useNftGroups, useSingleSeries} from "../APIs/apis";
import Loading from "../Components/Loading";

export default function SingleSeries() {
    const [searchParams] = useSearchParams();
    const singleSeries = useSingleSeries(searchParams.get("id"))
    const singleSeriesId = singleSeries?.data?.id

    const nftGroups = useNftGroups({
        enabled: !!singleSeriesId
    }, [singleSeriesId], [],)

    const actors = useActors([singleSeriesId], {
        enabled: !!singleSeriesId
    })

    return (
        singleSeries.isLoading ? <Loading/> : singleSeries.isSuccess &&
            <main>
                <div className="">
                    <img className="w-100 img-fluid single-series-top-image" alt=""
                         src={singleSeries.data.series_image_url}/>
                </div>
                <div className="text-center container mt-3">
                    <h2>{singleSeries.data.series_name}</h2>
                    <ReadMore text={singleSeries.data.series_description}/>
                </div>

                <ul className="nav auther-tabs mb-0 gap-3 border-bottom px-4 py-3 justify-content-center"
                    id="pills-tab"
                    role="tablist">
                    <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="pills-home-tab" data-bs-toggle="pill"
                                data-bs-target="#related-nfts" type="button" role="tab"
                                aria-controls="pills-home"
                                aria-selected="true">NFTs
                        </button>
                    </li>
                    <li className="nav-item" role="presentation">
                        <button className="nav-link" id="pills-profile-tab" data-bs-toggle="pill"
                                data-bs-target="#related-artists" type="button" role="tab"
                                aria-controls="pills-profile"
                                aria-selected="false">Artists
                        </button>
                    </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                    <div className="tab-pane fade show active" id="related-nfts" role="tabpanel"
                         aria-labelledby="pills-home-tab">
                        <div className="row m-1">
                            {
                                nftGroups.isLoading ? <Loading/> : nftGroups.isSuccess &&
                                    nftGroups.data.map((nftGroup, index) => {
                                        return (
                                            <NftGroupCard nftGroup={nftGroup} key={index}/>
                                        )
                                    })
                            } </div>
                    </div>
                    <div className="tab-pane fade" id="related-artists" role="tabpanel"
                         aria-labelledby="pills-profile-tab">
                        <div className="row m-1">
                            {
                                actors.isLoading ? <Loading/> : actors.isSuccess &&
                                    actors.data.map((actor, index) => {
                                        return (
                                            <ActorCard actor={actor} key={index}/>
                                        )
                                    })
                            }
                        </div>
                    </div>
                </div>
            </main>
    )
}