import React, {useState} from "react";
import SendVerificationViaEmail from "../../Components/SendVerificationViaEmail";
import NoticeModal from "../../Components/NoticeModal";
import {isValidEmailPattern} from "../../Helpers";
import {useSendUserForgetPasswordVerificationCode} from "../../APIs/apis";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";

export default function EnterEmailSection({email, setEmail, setStep}) {
    const [showNoticeModal, setShowNoticeModal] = useState(false)
    const [isEmailInputValid, setIsEmailInputValid] = useState(true)
    const [shouldCountDown, setShouldCountDown] = useState(false)
    const [count, setCount] = useState(60)
    const intl = useIntl()
    const sendVerificationCode = useSendUserForgetPasswordVerificationCode()

    const handleEmailChange = (e) => {
        setIsEmailInputValid(true)
        setEmail(e)
    }

    const handleSendVerificationCodeSuccess = () => {
        setIsEmailInputValid(true)
        setShowNoticeModal(true)
    }

    const handleNoticeModalClicked = () => {
        setShowNoticeModal(false)
        setStep(2)
    }

    const handleSendVerificationCodeError = (err) => {
        const errorData = err.response.data
        if (errorData.message === 'The email has not been registered.') {
            setIsEmailInputValid(false)
            toast.error(intl.formatMessage({id: 'email_not_registered'}))
        }

        if (errorData.message === 'The email needs to wait some time before sending verification code.') {
            setShouldCountDown(true)
            setCount(errorData.to_be_waited_seconds)
            toast.error(intl.formatMessage({id: 'send_verification_code_too_frequently'}))
        }
    }

    const isSendButtonEnabled = () => {
        return !shouldCountDown && isValidEmailPattern(email) && isEmailInputValid
    }

    const handleSendEmailButtonClick = (e) => {
        e.preventDefault()
        sendVerificationCode.mutate(email, {
            onError: handleSendVerificationCodeError,
            onSuccess: handleSendVerificationCodeSuccess
        })
    }


    return (
        <>
            <SendVerificationViaEmail
                title={intl.formatMessage({id: 'app.forget_password.enter_email.title'})}
                inputDescription={intl.formatMessage({id: 'app.forget_password.enter_email.description'})}
                email={email}
                setEmail={handleEmailChange}
                handleSendEmailButtonClick={handleSendEmailButtonClick}
                isEmailInputValid={isEmailInputValid}
                isSendButtonEnabled={isSendButtonEnabled}
                shouldCountDown={shouldCountDown}
                setShouldCountDown={setShouldCountDown}
                count={count}
                setCount={setCount}
                isSendButtonLoading={sendVerificationCode.isLoading}
            />
            <NoticeModal show={showNoticeModal}
                         title={intl.formatMessage({id: 'app.forget_password.enter_email.notice_modal.title'})}
                         handleClick={handleNoticeModalClicked}/>
        </>
    )
}
