import {RxCross1} from "react-icons/rx";
import {AiOutlineMenu} from "react-icons/ai";
import {Button} from "react-bootstrap";

export default function MobileNavbarButton({navbarClicked, setNavbarClicked}) {
    return (
        <>
            <Button
                className="bg-transparent text-black p-0 border border-0 fs-5"
                aria-controls={"navCollapsedItems"}
                aria-expanded={navbarClicked}
                onClick={() => setNavbarClicked((navbarClicked) => !navbarClicked)}
            >
                {
                    navbarClicked
                        ?
                        <RxCross1 className={"fs-1"}/>
                        :
                        <AiOutlineMenu className={"fs-1"}/>
                }
            </Button>
        </>
    )
}