import React, {useEffect, useState} from "react";

export default function VerificationCodeInput({
                                                  id,
                                                  name,
                                                  handleChange,
                                                  value,
                                                  setIsVerificationCodeFormatValid = null,
                                                  className,
                                                  isValid = true
                                              }) {
    const [borderColor, setBorderColor] = useState('lightgray')

    useEffect(() => {
        if (!isValid) {
            setBorderColor('red')
            return
        }

        if (value.length === 0) {
            setBorderColor('lightgray')
        } else if (value.length !== 0 && value.length < 6) {
            setBorderColor('red')
        } else {
            setBorderColor('black')
        }
    }, [value, isValid])

    useEffect(() => {
        if (setIsVerificationCodeFormatValid === null) {
            return
        }

        if (borderColor === 'black') {
            setIsVerificationCodeFormatValid(true)
            return
        }

        setIsVerificationCodeFormatValid(false)
    }, [borderColor, setIsVerificationCodeFormatValid])

    return (
        <input id={id}
               name={name}
               type={"password"}
               className={"form-control " + className}
               onChange={handleChange}
               placeholder={'請輸入驗證碼'}
               value={value}
               style={{borderColor: borderColor}}
               autoComplete={'verification_code'}
        />
    )
}