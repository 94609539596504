import React, {useEffect} from "react";
import {Link} from "react-router-dom";

export default function CompletePasswordReset({email, setStep}) {
    useEffect(() => {
        if (email === '') {
            setStep(1)
        }
    }, [email])
    return (
        <>
            <div className={"mb-5 fs-6"}>
                重設密碼完成!
            </div>

            <div className={"mb-1 mb-5"}>下次請使用新密碼登入</div>
            <Link to={"/login"} className={"one-dream-button form-control text-center"}
                  style={{textDecoration: 'none'}}>完成</Link>
        </>
    )
}