import {useActors} from "../APIs/apis";
import ActorRoundedCard from "../Components/ActorRoundedCard";
import Loading from "../Components/Loading";

export default function Actors() {
    const actors = useActors()
    return (
        <div>
            <main>
                <div className="container">
                    <h4 className="mt-4 mb-3">演員列表</h4>
                    <div className="row">
                        {
                            actors.isLoading ? <Loading/> : actors.isSuccess &&
                                actors.data.map((actor, index) => {
                                    return (
                                        <ActorRoundedCard actor={actor} key={index} isSliderItems={false}/>
                                    )
                                })
                        }
                    </div>
                </div>

            </main>
        </div>
    )
}
