import React, {useEffect, useState} from "react";
import PasswordRuleHintItem from "./PasswordRuleHintItem";

export default function PasswordRuleHints({password, className}) {
    const [isRuleOneMet, setIsRuleOneMet] = useState(false)
    const [isRuleTwoMet, setIsRuleTwoMet] = useState(false)

    useEffect(() => {
        // contain at least one english character and one number
        const ruleTwo = /^(?=.*[a-zA-Z])(?=.*\d)[a-zA-Z\d]+$/

        if (password.length >= 8 && password.length <= 16) {
            setIsRuleOneMet(true)
        } else {
            setIsRuleOneMet(false)
        }

        if (ruleTwo.test(password)) {
            setIsRuleTwoMet(true)
        } else {
            setIsRuleTwoMet(false)
        }

    }, [password])

    return (
        <div className={"d-flex flex-column gap-1 " + className}>
            <PasswordRuleHintItem text={"須 8 - 16 個字元"} isRuleMet={isRuleOneMet}/>
            <PasswordRuleHintItem text={"須包含英數字，不可使用特殊符號"} isRuleMet={isRuleTwoMet}/>
        </div>
    )
}