import React from "react";
import {ModalBody, ModalFooter} from "react-bootstrap";
import OneDreamButton from "./OneDreamButton";
import {Modal} from "react-bootstrap";

export default function NoticeModal({show, title, handleClick}) {
    return (
        <Modal show={show} centered={true}
               contentClassName={"rounded-modal-border"}>
            <Modal.Header className={"border-bottom-0"}>
                <Modal.Title><span
                    style={{fontSize: "15px"}}>{title}</span></Modal.Title>
            </Modal.Header>
            <ModalBody>

            </ModalBody>
            <ModalFooter className={"border-top-0"}>
                <OneDreamButton text={'確定'} enabled={true} onClick={handleClick}/>
            </ModalFooter>
        </Modal>
    )
}