import {useState, useEffect, useLayoutEffect} from "react";
import ImageWithFallbackUrl from "./ImageWithFallbackUrl";
import nftFallbackImg from "./Assets/nftFallbackImg.png"

export default function NftImage({imgSrc, imgAlt}) {
    const [imgSize, setImgSize] = useState({})
    const [imgStyle, setImgStyle] = useState({
        aspectRatio: "1",
        objectFit: "cover",
        objectPosition: "50% 50%"
    })

    useEffect(() => {
        const img = new Image()
        img.onload = function () {
            setImgSize((prevState) => {
                return {
                    ...prevState,
                    height: this.height,
                    width: this.width
                }
            })
        }

        img.src = imgSrc

        const fallbackImg = new Image()
        fallbackImg.onload = function () {
            setImgSize((prevState) => {
                return {
                    ...prevState,
                    height: this.height,
                    width: this.width
                }
            })
        }

        fallbackImg.src = nftFallbackImg
    }, [])

    useLayoutEffect(() => {
        if (Object.keys(imgSize).length !== 0) {
            const width = imgSize.width
            const height = imgSize.height
            setImgStyle((prevState) => {
                let styles = {}
                if (width > height) {
                    styles.height = "100%"
                    styles.width = "auto"
                } else {
                    styles.width = "100%"
                    styles.height = "auto"
                }

                return {
                    ...prevState, ...styles
                }
            })
        }
    }, [imgSize && Object.keys(imgSize).length !== 0])

    return (
        <div className={"nft-group-img-frame rounded"}>
            <ImageWithFallbackUrl imgUrl={imgSrc} fallbackImgUrl={nftFallbackImg} alt={imgAlt} style={imgStyle}
                                  className={"rounded shadow"}/>
        </div>
    )
}
