import React from "react";
import {useState} from "react";

export default ({text}) => {
    const [isReadMore, setIsReadMore] = useState(false);
    const toggleIsReadMore = () => {
        setIsReadMore(!isReadMore)
    }

    return (
        <p className="text">
            {!isReadMore ? text.slice(0, 75) : text}
            <span onClick={toggleIsReadMore} className="read-or-hide">
        {isReadMore ? " 顯示部分" : " ...顯示全部"}
      </span>
        </p>)
}