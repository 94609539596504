import React from "react";
import {Link} from "react-router-dom";

export default function ActorCard({actor}) {
    return (
        <div className="col-12 col-md-6 col-lg-4">
            <div className="">
                <div className="rounded bg-transparent card border border-secondary mb-3">
                    <div className={"actor-banner-frame"}>
                        <img src={actor.actor_banner_image_url}
                             className="rounded img-fluid"
                             style={{objectFit: "cover", overflow: "hidden"}}
                             alt={actor.actor_name + "_banner_image"}/>
                    </div>
                    <div className="m-auto">
                        <img src={actor.actor_avatar_image_url} alt="#"
                             className="img-fluid rounded-circle border-0 profile shadow-sm"
                             style={{objectFit: "cover", objectPosition: "50% 0%"}}
                        />
                        <Link to={`/single-actor?id=${actor.id}`} className={"stretched-link"}/>
                    </div>
                    <div className="card-body text-center py-0">
                        <h6 className="card-title mb-1 text-dark fw-bold mt-2">{actor.actor_name}</h6>
                    </div>
                </div>
            </div>
        </div>
    )
}