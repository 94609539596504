import React, {useEffect, useState} from "react";
import PasswordInputTemplate from "../../Components/PasswordInputTemplate";
import {isValidPasswordFormat} from "../../Helpers";

export default function PasswordResettingPasswordInput({
                                                           id,
                                                           name,
                                                           handleChange,
                                                           placeHolder,
                                                           value,
                                                           setIsPasswordFormatValid = null,
                                                           className
                                                       }) {
    const [passwordMasked, setPasswordMasked] = useState(true)
    const [borderColor, setBorderColor] = useState('lightgray')

    useEffect(() => {
        if (value.length === 0) {
            setBorderColor('lightgray')
            return
        }

        if (!isValidPasswordFormat(value)) {
            setBorderColor('red')
            return
        }

        setBorderColor('black')
    }, [value])

    useEffect(() => {
        if (setIsPasswordFormatValid === null) {
            return
        }

        if (borderColor === 'black') {
            setIsPasswordFormatValid(true)
            return
        }

        setIsPasswordFormatValid(false)
    }, [borderColor, setIsPasswordFormatValid])

    return (
        <PasswordInputTemplate
            passwordMasked={passwordMasked}
            setPasswordMasked={setPasswordMasked}
            handleChange={handleChange}
            placeHolder={placeHolder}
            id={id}
            name={name}
            value={value}
            borderColor={borderColor}
            className={className}
        />
    )

}
