import QABlock from "./QABlock";
import definedQAItems from "./questionsAndAnswers"

export default function QA() {
    return (
        <div className={"container mt-5"}>
            <div className={"fs-3 fw-bold"}>幫助中心</div>
            <div className={"mb-5"}>
                {
                    definedQAItems().map((item, index) => {
                        return (
                            <div className={"mt-3"} key={index}>
                                <QABlock question={(index + 1) + '.' + item.q}
                                         answer={item.a}/>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}