const NftDescriptionAccordionItem = ({description}) => {
    return (
        <div className="accordion-item mb-3">
            <h2 className="accordion-header" id="flush-nft-description">
                <button className="accordion-button collapsed p-0 fw-bold" type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#flush-collapse-nft-description"
                        aria-expanded="false"
                        aria-controls="flush-collapse-nft-description">
                    敘述
                </button>
            </h2>
            <div id="flush-collapse-nft-description" className="accordion-collapse collapse"
                 aria-labelledby="flush-nft-description">
                <div className="accordion-body p-0">
                    <div className="bg-light p-2 my-2 gap-2">
                        <p className="fs-6">{description}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default NftDescriptionAccordionItem