import React from "react";
import {Route, Routes} from "react-router-dom";

import Home from './Pages/Home'
import Explore from './Pages/Explore'
import Purchase from './Pages/Purchase'
import Purchased from "./Pages/Purchased";
import Wallet from "./Pages/Wallet"
import ProtectedRoute from "./Components/ProtectedRoute";
import Series from "./Pages/Series";
import Actors from "./Pages/Actors";
import SingleSeries from "./Pages/SingleSeries";
import SingleActor from "./Pages/SingleActor";
import NftGroup from "./Pages/NftGroup";
import Nft from "./Pages/Nft";
import {Layout} from "./Components/Layout";
import PersistentLogin from "./Components/PersistentLogin";
import MarketPlace from "./Pages/MarketPlace";
import SearchResult from "./Pages/SearchResult";
import ClaimNftsModule from "./Pages/ClaimNfts";
import More from "./Pages/More";
import ReferralCode from "./Pages/ReferralCode";
import PrivacyAndTermsOfUse from "./Pages/PrivacyAndUserTerm"
import QA from "./Pages/Q&A";
import OrganizationIntro from "./Pages/OrganizationIntro";
import Registration from "./Pages/Registration";
import Login from "./Pages/Login";
import ForgetPassword from "./Pages/ForgetPassword";
import ResetPassword from "./Pages/ResetPassword";

function App() {
    return (
        <Routes>
            <Route element={<Layout/>}>
                <Route path='/advertisement' element={<Home/>}/>
                <Route path='/' element={<Home/>}/>
                <Route path='series' element={<Series/>}/>
                <Route path='login' element={<Login/>}/>
                <Route path='registration' element={<Registration/>}/>
                <Route path='forget-password' element={<ForgetPassword/>}/>
                <Route path='reset-password' element={<ResetPassword/>}/>
                <Route path='market' element={<MarketPlace/>}/>
                <Route path='actors' element={<Actors/>}/>
                <Route path="explore" element={<Explore/>}/>
                <Route path="search-result" element={<SearchResult/>}/>
                <Route path="nft-group" element={<NftGroup/>}/>
                <Route path="nft" element={<Nft/>}/>
                <Route path="single-series" element={<SingleSeries/>}/>
                <Route path="single-actor" element={<SingleActor/>}/>
                <Route path="more" element={<More/>}/>
                <Route path="privacy-and-terms-of-use" element={<PrivacyAndTermsOfUse/>}/>
                <Route path="qa" element={<QA/>}/>
                <Route path="organisation-intro" element={<OrganizationIntro/>}/>
                <Route element={<PersistentLogin/>}>
                    <Route element={<ProtectedRoute redirectPath={"login"}/>}>
                        <Route path="referral-code" element={<ReferralCode/>}/>
                        {/*<Route path="claim-nfts" element={<ClaimNftsModule/>}/>*/}
                        <Route path="purchase" element={<Purchase/>}/>
                        <Route path="wallet" element={<Wallet/>}/>
                        <Route path="purchased" element={<Purchased/>}/>
                    </Route>
                </Route>
                <Route path="*" element={<Home/>}/>
            </Route>
        </Routes>
    );
}

export default App;
