import About from "./About";
import Support from "./Support";
import ClientService from "./ClientService";
import CopyrightPrivacy from "./Copyright&Privacy";

export default function Footer() {
    return (
        <div style={{backgroundColor: "#F5F5F5"}}>
            <div className={"container pt-4 pb-5 mt-5"}>
                <div className={"d-lg-none d-flex flex-column text-center gap-4"}>
                    <About/>
                    <Support/>
                    <ClientService/>
                    <CopyrightPrivacy/>
                </div>
                <div className={"d-none d-lg-flex justify-content-between"}>
                    <div className={"d-flex gap-4"}>
                        <About/>
                        <Support/>
                        <ClientService/>
                    </div>
                    <div>
                        <CopyrightPrivacy/>
                    </div>
                </div>
            </div>
        </div>
    )
}