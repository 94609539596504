import {MdKeyboardArrowUp} from "react-icons/md";
import {MdKeyboardArrowDown} from "react-icons/md";
import {useState} from "react";

export default function QABlock({question, answer}) {
    const getStyle = () => {
        return displayAnswer ?
            {
                backgroundColor: "rgba(0, 200, 137, 0.05)",
                borderRadius: "13px",
            } :
            {
                backgroundColor: "white",
                border: "1px solid #D9D9D9",
                borderRadius: "13px",
            }
    }

    const [displayAnswer, setDisplayAnswer] = useState(false)

    const handleClick = () => {
        setDisplayAnswer((state) => !state)
    }

    return (
        <>
            <div className={"p-3 d-flex justify-content-between"}
                 style={getStyle()}
            >
                <div className={"d-flex flex-column gap-2 w-100"}>
                    <div className={"fs-6 fw-bold"} style={{color: "#1F926F"}}
                         role={"button"}
                         onClick={handleClick}
                         dangerouslySetInnerHTML={{__html: question}}/>
                    {
                        displayAnswer &&
                        <div className={"fs-6"} dangerouslySetInnerHTML={{__html: answer}}/>
                    }
                </div>
                <div className={"fs-4"} style={{color: "#1F926F"}}
                     role={"button"}
                     onClick={handleClick}
                >
                    {
                        displayAnswer ? <MdKeyboardArrowUp/>
                            : <MdKeyboardArrowDown/>
                    }
                </div>
            </div>
        </>

    )
}