import React, {useEffect, useState} from "react";
import VerificationCodeInput from "../../Components/VerificationCodeInput";
import SendVerificationCodeEmailButton from "../../Components/SendVerificationCodeEmailButton";
import RegisterPasswordInput from "../../Components/RegisterPasswordInput";
import PasswordRuleHints from "../../Components/PasswordRuleHints";
import PasswordRuleHintItem from "../../Components/PasswordRuleHintItem";
import OneDreamButton from "../../Components/OneDreamButton";
import {useRegisterUser, useSendUserRegistrationVerificationCode} from "../../APIs/apis";
import toast from "react-hot-toast";

export default function RegistrationFormSection({form, handleChange, setStep}) {
    const [isValidVerificationCodeFormat, setIsValidVerificationCodeFormat] = useState(false)
    const [isValidVerificationCode, setIsValidVerificationCode] = useState(true)
    const [passwordValid, setPasswordValid] = useState(false)
    const [newPassword, setNewPassword] = useState("")
    const register = useRegisterUser()

    const sendVerificationCode = useSendUserRegistrationVerificationCode()

    const handleRegisterError = (err) => {
        const errorData = err.response.data
        if ('verification_code' in errorData.errors) {
            toast.error("驗證碼錯誤")
            setIsValidVerificationCode(false)
        }
    }

    const handleRegisterSuccess = () => {
        setStep(3)
    }

    const handleSubmitButtonClicked = (e) => {
        e.preventDefault()
        register.mutate(form, {
            onError: handleRegisterError,
            onSuccess: handleRegisterSuccess
        })
    }

    const handleVerificationCodeChange = (e) => {
        setIsValidVerificationCode(true)
        handleChange(e)
    }

    const getSubmitButtonEnabled = () => {
        return isValidVerificationCodeFormat && passwordValid && newPassword === form.password
    }

    useEffect(() => {
        if (form.email === "") {
            setStep(1)
        }
    })

    return (
        <form>
            <label htmlFor={"verification_code"} className={"mb-1"}>輸入驗證碼</label>
            <VerificationCodeInput className={"mb-2"} id={'verification_code'} name={'verification_code'}
                                   value={form.verification_code}
                                   setIsVerificationCodeFormatValid={setIsValidVerificationCodeFormat}
                                   isValid={isValidVerificationCode}
                                   handleChange={handleVerificationCodeChange}/>
            <SendVerificationCodeEmailButton email={form.email} sendVerificationCode={sendVerificationCode}/>
            <p className={"mt-2"}>沒收到驗證信嗎? 請與我們的 <a
                href={`mailto:${process.env.REACT_APP_SUPPORT_EMAIL}`}
                target={"_blank"}
                rel="noreferrer noopener"
            >客服</a> 聯繫</p>
            <div className={"mt-5"}>
                <label htmlFor={"password"} className={"mb-1"}>建立密碼</label>
                <RegisterPasswordInput id={"password"} name={"password"} value={form.password}
                                       handleChange={handleChange}
                                       setPasswordInputValid={setPasswordValid}
                                       placeHolder={"請輸入 8 - 16 位之間的半形英數字"}/>
            </div>

            <PasswordRuleHints className={"mt-2"} password={form.password}/>

            <label htmlFor={"new_password"} className={"mt-5 mb-1"}>再次輸入您的密碼</label>
            <RegisterPasswordInput id={"new_password"} name={"new_password"} value={newPassword}
                                   placeHolder={"再次輸入以便確認"}
                                   handleChange={(e) => setNewPassword(e.target.value)}
                                   setPasswordInputValid={setPasswordValid}
                                   className={"mb-2"}
            />
            <PasswordRuleHintItem className={"mb-5"} text={"兩次輸入的密碼須一致"}
                                  isRuleMet={newPassword === form.password}/>
            <OneDreamButton text={'確定'} enabled={getSubmitButtonEnabled()} onClick={handleSubmitButtonClicked}
                            isLoading={register.isLoading}
            />
        </form>
    )
}