import React from "react";
import {AiFillCheckCircle} from "react-icons/ai";

export default function PasswordRuleHintItem({text, isRuleMet, className}) {
    const getIconStyle = () => {
        const iconColor = isRuleMet === false ? "#D9D9D9" : "#00B079"
        return {
            color: iconColor,
        }
    }
    return (
        <div className={"d-flex align-items-center gap-1 " + className}>
            <AiFillCheckCircle className={"fs-5"} style={getIconStyle()}/>
            <div>{text}</div>
        </div>
    )
}