import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useState} from "react";
import MintedNftVersions from "../Components/MintedNftVersions";
import OnSaleNftVersions from "../Components/OnSaleNftVersions";
import {useSingleNftGroup, useNfts, usePaginatedNftTrxRecords} from "../APIs/apis";
import {useEffect} from "react";
import Loading from "../Components/Loading";
import {getBlockchainLogo} from "../Helpers";
import NftTxRecordAccordionItem from "../Components/NftTxRecordAccordionItem";
import NftDescriptionAccordionItem from "../Components/NftDescriptionAccordionItem";
import NftImage from "../Components/NftImage";

const NftGroup = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams();
    const nftGroupId = searchParams.get("id")
    const [mintedNftsModalShow, setMintedNftsModalShow] = useState(false)
    const [showOnSaleNftsModal, setShowOnSaleNftsModal] = useState(false)
    const nftTxRecords = usePaginatedNftTrxRecords([nftGroupId])
    const mintedNfts = useNfts([nftGroupId], null)
    const singleNftGroup = useSingleNftGroup(nftGroupId)

    const onSaleNfts = useNfts([nftGroupId], 1)

    useEffect(() => {
        if (singleNftGroup.status === 'error') {
            navigate('/')
        }
    }, [singleNftGroup.status, navigate])

    return (
        singleNftGroup.isLoading ? <Loading/> : singleNftGroup.isSuccess &&
            <section className="py-2">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pe-lg-5">
                            <div className="sidebar-fix card osahan-item-list bg-transparent border-0">
                                {singleNftGroup.isSuccess
                                    ? <>
                                        <NftImage imgSrc={singleNftGroup?.data.nft_group_image_url}
                                                  imgAlt={singleNftGroup.nft_group_name + '_image'}
                                        />
                                        <div className="card-header border-0 bg-transparent p-0 mb-3 mt-3">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex gap-2 align-items-center position-relative">
                                                    <div>
                                                        <img
                                                            src={singleNftGroup?.data?.series?.series_image_url}
                                                            alt={singleNftGroup?.data?.series?.series_name}
                                                            className="img-fluid rounded-circle"
                                                            style={{objectFit: "cover"}}/>
                                                    </div>
                                                    <div
                                                        className="small fw-bold">{singleNftGroup?.data?.series?.series_name}
                                                    </div>
                                                    <Link to={`/single-series?id=${singleNftGroup?.data?.series?.id}`}
                                                          className={"stretched-link"}/>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                    : 'is loading'}
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="mb-4 mt-2">
                                <h1 className="fw-bold text-dark mb-3">{singleNftGroup?.data?.nft_group_name}</h1>
                                <div className="d-flex justify-content-between">
                                    <p>已有 {singleNftGroup?.data?.nft_owner_count} 個人持有</p>
                                    <p>{onSaleNfts?.data?.length} 個版本可購買</p>
                                </div>
                                <div className="bg-light p-2 mb-3">
                                    <div
                                        className="d-flex justify-content-between align-items-center border-radius-five">
                                        <div className="">目前最低價格</div>
                                        <div>
                                            <img className="img-fluid"
                                                 src={getBlockchainLogo(singleNftGroup.data.chain_id)}
                                                 alt="icon1"
                                                 style={{height: '25px'}}
                                            />
                                        </div>
                                    </div>
                                    <h3>{`${Number(singleNftGroup?.data?.min_nft_price).toFixed(4)} ${singleNftGroup?.data?.currency}`}</h3>
                                    <div className="d-flex gap-2">
                                        <div>平均價格</div>
                                        <i className="bi bi-info-circle"></i>
                                        <div>{`${Number(singleNftGroup?.data?.avg_nft_price).toFixed(4)} ${singleNftGroup?.data?.currency}`}</div>
                                    </div>
                                </div>

                                <div className="mb-3">
                                    <button
                                        className="btn btn-dark form-control rounded-lg"
                                        disabled={onSaleNfts?.data?.length === 0}
                                        onClick={() => {
                                            setShowOnSaleNftsModal(true)
                                        }}
                                    >
                                        選擇版本並購買
                                    </button>
                                </div>

                                {mintedNfts.isSuccess && (<MintedNftVersions
                                    show={mintedNftsModalShow}
                                    onHide={() => {
                                        setMintedNftsModalShow(false)
                                    }}
                                    mintedNfts={mintedNfts?.data}
                                />)}

                                {onSaleNfts.isSuccess && (<OnSaleNftVersions
                                    show={showOnSaleNftsModal}
                                    onHide={() => {
                                        setShowOnSaleNftsModal(false)
                                    }}
                                    nft={singleNftGroup?.data}
                                    onSaleNfts={onSaleNfts?.data}
                                />)}

                                <div className={"accordion accordion-flush"}>
                                    <div className="accordion-item mb-3">
                                        <h2 className="accordion-header" id="flush-nft-details">
                                            <button className="accordion-button collapsed p-0 fw-bold" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapse-nft-details"
                                                    aria-expanded="false" aria-controls="flush-collapse-nft-details">
                                                詳細資料
                                            </button>
                                        </h2>
                                        <div id="flush-collapse-nft-details" className="accordion-collapse collapse"
                                             aria-labelledby="flush-nft-details">
                                            <div className="accordion-body p-0">
                                                <div className="d-flex bg-light p-2 my-2 gap-2">
                                                    <div>{singleNftGroup?.data?.minted_quantity} 個版本已被鑄造</div>
                                                    <i className="bi bi-info-circle"></i>
                                                    <div
                                                        role={"button"}
                                                        onClick={() => setMintedNftsModalShow(true)}
                                                    >查看全部
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <NftDescriptionAccordionItem
                                        description={singleNftGroup?.data?.nft_group_description}/>
                                    <NftTxRecordAccordionItem nftTxRecords={nftTxRecords}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default NftGroup