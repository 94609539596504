import React, {useState} from "react";
import PasswordRuleHints from "../../Components/PasswordRuleHints";
import PasswordRuleHintItem from "../../Components/PasswordRuleHintItem";
import OneDreamButton from "../../Components/OneDreamButton";
import OriginalPasswordInput from "./OriginalPasswordInput";
import {handleFormChangeTemplate} from "../../Helpers";
import NoticeModal from "../../Components/NoticeModal";
import {useLocation, useNavigate} from "react-router-dom";
import {useResetPassword} from "../../APIs/apis";
import PasswordResettingPasswordInput from "./PasswordResettingPasswordInput";
import toast from "react-hot-toast";
import {useIntl} from "react-intl";
import ResetPasswordRuleHints from "./ResetPasswordRuleHints";

export default function ResetPassword() {
    const [form, setForm] = useState({password: "", new_password: ""})
    const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
    const [isPasswordValid, setIsPasswordValid] = useState(true)
    const [isPasswordFormatValid, setIsPasswordFormatValid] = useState(false)
    const [isNewPasswordFormatValid, setIsNewPasswordFormatValid] = useState(false)
    const [showNoticeModal, setShowNoticeModal] = useState(false)
    const intl = useIntl()
    const location = useLocation()
    const navigate = useNavigate()
    const resetPassword = useResetPassword()

    const handleResetPasswordError = (error) => {
        const res = error.response
        if (res.status === 403) {
            setIsPasswordValid(false)
            toast.error(intl.formatMessage({id: 'incorrect_original_password'}))
        }
    }

    const handleResetPasswordSuccess = (data) => {
        setShowNoticeModal(true)
    }

    const handleSubmitButtonClicked = (e) => {
        e.preventDefault()
        resetPassword.mutate(form, {
            onSuccess: handleResetPasswordSuccess,
            onError: handleResetPasswordError
        })
    }

    const handleNoticeModalClicked = () => {
        navigate('/more', {from: location, replace: true})
    }

    const handlePasswordChanged = (e) => {
        handleFormChangeTemplate(e, setForm)
        setIsPasswordValid(true)
    }

    const handleNewPasswordChanged = (e) => {
        handleFormChangeTemplate(e, setForm)
    }

    const getSubmitButtonEnabled = () => {
        return isPasswordValid && isPasswordFormatValid && isNewPasswordFormatValid && newPasswordConfirmation === form.new_password && form.password !== form.new_password
    }

    return (
        <div className={"container p-4"}>
            <h2 className={"mb-5"}>變更密碼</h2>
            <form>
                <label>原密碼</label>
                <OriginalPasswordInput id={'password'} name={'password'} value={form.password}
                                       setIsPasswordFormatValid={setIsPasswordFormatValid}
                                       placeHolder={'請輸入原密碼'}
                                       isPasswordValid={isPasswordValid}
                                       handleChange={handlePasswordChanged}/>
                <div className={"mt-5"}>
                    <label htmlFor={"new_password"} className={"mb-1"}>新密碼</label>
                    <PasswordResettingPasswordInput id={"new_password"} name={"new_password"} value={form.new_password}
                                                    handleChange={handleNewPasswordChanged}
                                                    setIsPasswordFormatValid={setIsNewPasswordFormatValid}
                                                    placeHolder={"請輸入 8 - 16 位之間的半形英數字"}/>
                </div>

                <ResetPasswordRuleHints className={"mt-2"} newPassword={form.new_password} currentPassword={form.password}/>

                <label htmlFor={"new_password_confirmation"} className={"mt-5 mb-1"}>再次輸入新密碼</label>
                <PasswordResettingPasswordInput id={"new_password_confirmation"} name={"new_password_confirmation"}
                                                value={newPasswordConfirmation}
                                                placeHolder={"再次輸入以便確認"}
                                                handleChange={(e) => setNewPasswordConfirmation(e.target.value)}
                                                setIsPasswordFormatValid={setIsPasswordFormatValid}
                                                className={"mb-2"}
                />
                <PasswordRuleHintItem className={"mb-5"} text={"兩次輸入的密碼須一致"}
                                      isRuleMet={newPasswordConfirmation === form.new_password}/>
                <OneDreamButton text={'確定'} enabled={getSubmitButtonEnabled()} onClick={handleSubmitButtonClicked}/>
            </form>
            <NoticeModal title={"設置成功"} show={showNoticeModal} handleClick={handleNoticeModalClicked}/>
        </div>
    )
}
