import {useState} from "react";

export default function ImageWithFallbackUrl({imgUrl, fallbackImgUrl, alt, className, style}) {
    const [finalImgUrl, setFinalImgUrl] = useState(imgUrl)
    const handleError = (e) => {
        console.error(e.target.url + ' 連結失效')
        setFinalImgUrl(fallbackImgUrl)
    }

    return (
        <img src={finalImgUrl} onError={handleError} alt={alt} className={className} style={style}/>
    )
}