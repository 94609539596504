import getAxios from "./axios";
import {useEffect} from "react";
import {useRefreshToken} from "./apis";
import {useRecoilState} from "recoil";
import authAtom from "../States/auth";

const normalAxios = getAxios()
const axiosPrecognitive = getAxios({
    "precognitive": "true"
})

const useAxiosPrivate = (isPrecognitive = false) => {

    const axios = isPrecognitive ? axiosPrecognitive : normalAxios;

    const refresh = useRefreshToken();
    const [auth] = useRecoilState(authAtom)

    useEffect(() => {
        const requestIntercept = axios.interceptors.request.use(
            config => {
                if (!config.headers['Authorization']) {
                    config.headers['Authorization'] = `Bearer ${auth?.accessToken}`;
                }
                return config;
            },
            (error) => Promise.reject(error)
        );

        const responseIntercept = axios.interceptors.response.use(response => response,
            async (error) => {
                const prevRequest = error?.config;
                if (error?.response?.status === 401 && !prevRequest?.sent && prevRequest.url !== 'one-dream/user/refresh-token') {
                    const newAccessToken = await refresh();

                    return axios({
                        ...prevRequest,
                        sent: true,
                        headers: {
                            ...prevRequest.headers,
                            Authorization: `Bearer ${newAccessToken}`,
                        }
                    });
                }
                return Promise.reject(error)
            }
        );

        return () => {
            axios.interceptors.request.eject(requestIntercept);
            axios.interceptors.response.eject(responseIntercept);
        }
    }, [auth, refresh, axios])

    return axios;
}

export default useAxiosPrivate;