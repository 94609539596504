import {useSeries} from "../APIs/apis";
import SeriesCard from "../Components/SeriesCard";
import Loading from "../Components/Loading";


export default function Series() {
    const series = useSeries({staleTime: 5000})

    return (
        <main>
            <div className="container">
                <h4 className="mt-4 mb-3">戲劇列表</h4>
                <div className="row">
                    {
                        series.isLoading ? <Loading/> : series.isSuccess &&
                            series.data.map((singleSeries, index) => {
                                return (
                                    <SeriesCard series={singleSeries} key={index} isSliderItems={false}/>
                                )
                            })
                    }
                </div>
            </div>
        </main>
    )
}