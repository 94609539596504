import {Modal} from "react-bootstrap";
import {Button} from "react-bootstrap";
import Loading from "./Loading";
import {useLoginWithMetaMask} from "../APIs/apis";

export default function LoginInputModal({show, onHide}) {
    const loginMutation = useLoginWithMetaMask()

    return (
        <>
            <Modal show={show} onHide={() => onHide()}>
                <Modal.Header closeButton>
                    <Modal.Title>請連接錢包</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    {
                        loginMutation.isLoading ? <Loading/> : <>
                            <Button variant="secondary" onClick={() => onHide()}>
                                Close
                            </Button>
                            <Button variant="primary" onClick={async () => await loginMutation.mutateAsync()}>
                                連接
                            </Button>
                        </>
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}
