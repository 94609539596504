import React from "react";

export default function RememberAccountCheckBox({id, name, handleChange, value, checked}) {
    return (
        <div className={"d-flex align-items-end gap-2 mt-2"}>
            <input id={id} name={name} type={"checkbox"}
                   onChange={handleChange}
                   value={value}
                   checked={checked}
                   className={"form-check-input border-2 border-dark border-radius-five"}
                   style={{height: "20px", width: "20px"}}
            />
            <label className={"form-check-label"} htmlFor={id}>記住我的帳號</label>
        </div>
    )
}