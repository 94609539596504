import {Link} from "react-router-dom";

export default function Support() {
    return (
        <div>
            <div className={"one-dream-footer-title mb-2"}>支援服務</div>
            <div role={"button"} className={"one-dream-footer-text mb-2 position-relative"}><Link to={'/qa'} className={"stretched-link"}/>常見問題</div>
            {/*<div role={"button"} className={"one-dream-footer-text"}>立即註冊</div>*/}
        </div>

    )
}