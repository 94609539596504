import React, {useEffect, useState} from "react";
import PasswordInputTemplate from "../../Components/PasswordInputTemplate";

export default function OriginalPasswordInput({
                                                  id,
                                                  name,
                                                  handleChange,
                                                  placeHolder,
                                                  value,
                                                  setIsPasswordFormatValid = null,
                                                  isPasswordValid = true,
                                                  className
                                              }) {
    const [passwordMasked, setPasswordMasked] = useState(true)
    const [borderColor, setBorderColor] = useState('lightgray')

    useEffect(() => {
        if (!isPasswordValid) {
            setBorderColor('red')
            return
        }

        if (value.length === 0) {
            setBorderColor('lightgray')
        } else {
            setBorderColor('black')
        }
    }, [value, isPasswordValid, setBorderColor])

    useEffect(() => {
        if (setIsPasswordFormatValid === null) {
            return
        }

        if (borderColor === 'black') {
            setIsPasswordFormatValid(true)
            return
        }

        setIsPasswordFormatValid(false)
    }, [borderColor, setIsPasswordFormatValid])

    return (
        <PasswordInputTemplate
            passwordMasked={passwordMasked}
            setPasswordMasked={setPasswordMasked}
            handleChange={handleChange}
            placeHolder={placeHolder}
            id={id}
            name={name}
            value={value}
            borderColor={borderColor}
            className={className}
        />
    )

}
