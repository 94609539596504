import {Modal, ModalBody} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {Spinner} from "react-bootstrap";

export default function PurchasingModal({show, onHide, isPurchasing, purchaseNft}) {
    return (
        <Modal show={show} onHide={() => onHide()} centered={true} backdrop={"static"} keyboard={false}
               contentClassName={"rounded-modal-border"}>
            <Modal.Header closeButton className={"border-bottom-0"}>
                <Modal.Title className={"ms-auto"}><span
                    style={{fontSize: "15px"}}>畫面進行中，請勿跳轉。</span></Modal.Title>
            </Modal.Header>
            <ModalBody className={"border-bottom-0"}>
                {
                    !isPurchasing
                        ?
                        <Modal.Footer className={"border-top-0"}>
                            <Button variant="primary" onClick={purchaseNft}>
                                繼續
                            </Button>
                        </Modal.Footer>
                        :
                        <div className={"text-center mb-5"}>
                            <Spinner animation={"border"} role={"status"}
                                     style={{height: "60px", width: "60px", fontSize: "30px", color: "lightgray"}}/>
                        </div>
                }
            </ModalBody>
        </Modal>
    )
}
