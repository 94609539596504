export default function ReferralTabs({clickedTab, setClickedTab}) {
    const defaultTabClasses = () => {
        return 'py-2 px-5 fs-6 border-bottom border-gray border-2'
    }

    const tabClickedClass = () => {
        return defaultTabClasses() + ' border-dark fw-bold'
    }

    return (
        <div className={"mt-4 d-flex justify-content-between"}>
            <div role={"button"}
                 className={clickedTab === 'my-referral-code' ? tabClickedClass() : defaultTabClasses()}
                 onClick={() => setClickedTab('my-referral-code')}
            >推薦朋友
            </div>
            <div role={"button"}
                 className={clickedTab === 'enter-referral-code' ? tabClickedClass() : defaultTabClasses()}
                 onClick={() => setClickedTab("enter-referral-code")}
            >輸入推薦碼
            </div>
        </div>
    )
}