import {useNavigate} from "react-router-dom";

export default function MobileSearchBox({handleClose, search, setSearch}) {
    const navigate = useNavigate()
    return (
        <div className={"d-flex align-items-center p-3 gap-3"}>
            <div>
                <button className={"border-0 bg-transparent"}
                        onClick={handleClose}
                >
                    <i className="bi bi-chevron-left" style={{fontSize: "25px", fontWeight: "bolder"}}></i>
                </button>
            </div>
            <div className={"w-100"}>
                <form
                    onSubmit={(event) => {
                        event.preventDefault()
                        navigate(`/search-result?search=${search}`)
                    }}
                    className="d-flex gap-2 align-items-center overflow-hidden p-2 px-3 border-radius-five border-0"
                    style={{backgroundColor: "#F0F0F0"}}
                >
                    <div>
                        <i className="bi bi-search" style={{color: "#BFBFBF", fontSize: "18px"}}></i>
                    </div>

                    <input type="text" placeholder="Search OneDream"
                           className="border-0 p-1 form-control form-control-sm bg-transparent fs-6"
                           onChange={(event) => setSearch(event.target.value)}
                           value={search}
                    />
                </form>
            </div>
        </div>
    )
}