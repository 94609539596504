import {Link} from "react-router-dom";

export default function ActorRoundedCard({actor, isSliderItems}) {
    const classesForRow = "col-4 col-md-3 col-lg-2"

    return (
        <div
            className={"card osahan-item-list p-3 rounded h-100 bg-transparent border-0 " + (isSliderItems ? '' : classesForRow)}>
            <div className="ratio ratio-1x1 rounded-circle overflow-hidden mb-3">
                <img src={actor.actor_avatar_image_url} className="img-fluid"
                     style={{objectFit: "cover", objectPosition: "50% 0%"}}
                     alt="..."/>
            </div>
            <p className="text-center">{actor.actor_name}</p>
            <Link to={`/single-actor?id=${actor.id}`} className={"stretched-link"}/>
        </div>
    )
}