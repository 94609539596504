import React from "react";
import {AiFillEye} from "react-icons/ai";
import {AiFillEyeInvisible} from "react-icons/ai";

export default function PasswordInputTemplate({passwordMasked, setPasswordMasked, handleChange, placeHolder, id, name, value, borderColor, className}) {
    return (
        <div className={"form-control d-flex justify-content-between align-items-center " + className}
             style={{borderColor: borderColor}}>
            <input id={id}
                   name={name}
                   type={passwordMasked ? 'password' : ''}
                   className={"border-0 m-0 p-0 w-100 h-100"}
                   onChange={handleChange}
                   placeholder={placeHolder}
                   value={value}
                   style={{outline: "none"}}
                   autoComplete={"current-password"}
            />
            {
                passwordMasked ?
                    <AiFillEye role={"button"} onClick={() => setPasswordMasked((prev) => false)}/>
                    :
                    <AiFillEyeInvisible role={"button"} onClick={() => setPasswordMasked((prev) => true)}/>
            }
        </div>
    )
}