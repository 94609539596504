import {useSearchParams} from "react-router-dom";
import {useState} from "react";
import MintedNftVersions from "../Components/MintedNftVersions";
import {useNfts, useNft, useUserSelf} from "../APIs/apis";
import {usePaginatedNftTrxRecords} from "../APIs/apis";
import _ from "lodash";
import authAtom from "../States/auth";
import {useRecoilState} from "recoil";
import {useNavigate} from "react-router-dom";
import Loading from "../Components/Loading";
import {useLoginWithMetaMask} from "../APIs/apis";
import {getBlockchainLogo, getRoundedNftPrice} from "../Helpers";
import NftTxRecordAccordionItem from "../Components/NftTxRecordAccordionItem";
import NftDescriptionAccordionItem from "../Components/NftDescriptionAccordionItem";
import {Spinner} from "react-bootstrap";
import loginModalAtom from "../States/loginModal";
import NftImage from "../Components/NftImage";


const Nft = () => {
    const [showMintedNftsModal, setShowMintedNftsModal] = useState(false)
    const [searchParams] = useSearchParams();
    const nftId = searchParams.get("id")
    const [auth] = useRecoilState(authAtom)
    const login = useLoginWithMetaMask()
    const [, setShowLoginModal] = useRecoilState(loginModalAtom)

    const nft = useNft(nftId)
    const nftGroupId = nft?.data?.nft_group?.id
    const mintedNfts = useNfts([nftGroupId], null, {
        enabled: !!nftGroupId
    })

    const user = useUserSelf({
        enabled: !!auth.accessToken
    })

    const nftTrxRecords = usePaginatedNftTrxRecords([nftGroupId])
    const navigate = useNavigate()

    const handlePurchase = async () => {
        if (!auth.accessToken) {
            setShowLoginModal(true)
            return
        }

        const purchaseUrl = `/purchase?nft_id=${nft.data?.id}`

        navigate(purchaseUrl)
    }

    return (
        nft.isLoading ? <Loading/> : nft.isSuccess &&
            <section className="py-2">
                <div className="container py-4">
                    <div className="row">
                        <div className="col-lg-6 col-12 pe-lg-5">
                            <div className="sidebar-fix card osahan-item-list bg-transparent border-0">
                                <NftImage imgSrc={nft.data.nft_image_url} imgAlt={nft.data.nft_name + "_image"}/>
                                <div className="card-header border-0 bg-transparent p-0 mb-3 mt-3">
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex gap-2 align-items-center">
                                            <div><img src={nft.data?.nft_group?.series.series_image_url}
                                                      alt={nft.data?.nft_group?.series.series_name + '-image'}
                                                      className="img-fluid rounded-circle"/></div>
                                            <div
                                                className="small fw-bold">{nft.data?.nft_group?.series.series_name}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6 col-12">
                            <div className="mb-4 mt-2">
                                <div className={"d-flex gap-2"}>
                                    <h1 className="fw-bold text-dark">{nft.data.nft_name}</h1>
                                </div>
                                <div className={"mb-2"}>
                                    {_.truncate(nft.data.owner.wallet_address, {
                                        length: 12
                                    })} <span className={"fw-light"}>已持有</span>
                                </div>
                                {
                                    nft.data.nft_price !== null &&
                                    <>
                                        <div className="bg-light p-2 mb-3">
                                            <div
                                                className="d-flex justify-content-between align-items-center border-radius-five">
                                                <div className="">目前價格</div>
                                                <div>
                                                    <img className="img-fluid"
                                                         src={getBlockchainLogo(nft.data.chain_id)}
                                                         alt="icon1"
                                                         style={{height: '25px'}}
                                                    />
                                                </div>
                                            </div>
                                            <h3>{`${getRoundedNftPrice(nft.data.nft_price)} ${nft.data.currency}`}</h3>
                                        </div>
                                        <div className="mb-3">
                                            <button
                                                className="btn btn-dark form-control rounded-lg"
                                                onClick={() => handlePurchase()}
                                                disabled={user.isSuccess && user.data.id === nft.data.owner.id}
                                            >{
                                                login.isLoading
                                                    ? <Spinner size={"sm"} animation={"border"} role={"status"}/>
                                                    : auth.accessToken && user.isSuccess && user.data.id === nft.data.owner.id
                                                        ? '您擁有此 NFT'
                                                        : '購買'
                                            }
                                            </button>
                                        </div>
                                    </>
                                }

                                <div className={"accordion accordion-flush"}>
                                    <div className="accordion-item mb-3">
                                        <h2 className="accordion-header" id="flush-nft-details">
                                            <button className="accordion-button collapsed p-0 fw-bold" type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target="#flush-collapse-nft-details"
                                                    aria-expanded="false" aria-controls="flush-collapse-nft-details">
                                                詳細資料
                                            </button>
                                        </h2>
                                        <div id="flush-collapse-nft-details" className="accordion-collapse collapse"
                                             aria-labelledby="flush-nft-details">
                                            <div className="accordion-body p-0">
                                                {
                                                    nft.data.minted_at &&
                                                    <p className={"mt-3"}>鑄造時間： {nft.data.minted_at}</p>
                                                }
                                                <div className="d-flex bg-light p-2 mb-2 gap-2">
                                                    <div>{nft.data.nft_group.minted_quantity} 個版本已被鑄造</div>
                                                    <i className="bi bi-info-circle"></i>
                                                    <div
                                                        role={"button"}
                                                        onClick={() => setShowMintedNftsModal(true)}
                                                    >查看全部
                                                    </div>
                                                </div>
                                                {
                                                    nft.data.ipfs_url &&
                                                    <div className="d-flex bg-light p-2 mb-2 gap-2 align-items-center">
                                                        <i className="bi bi-eye" style={{fontSize: '1.5em'}}></i>
                                                        <a role={"button"} target={"_blank"} rel={"noreferrer"}
                                                           href={nft.data.ipfs_url}>檢視原檔</a>
                                                    </div>
                                                }
                                                {
                                                    nft.data.minted_at &&
                                                    <div className="d-flex bg-light p-2 mb-2 gap-2 align-items-center">
                                                        <img alt={"blockchain icon"} className={"img-fluid"}
                                                             src={getBlockchainLogo(nft.data.chain_id)}
                                                             style={{height: '17px'}}
                                                        />
                                                        <a role={"button"} target={"_blank"} rel={"noreferrer"}
                                                           href={nft.data.scan_service_url}>View
                                                            on {nft.data.scan_service_name}</a>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <NftDescriptionAccordionItem description={nft?.data.nft_description}/>
                                    <NftTxRecordAccordionItem nftTxRecords={nftTrxRecords}/>
                                </div>
                                {
                                    mintedNfts.isSuccess &&
                                    <MintedNftVersions
                                        show={showMintedNftsModal}
                                        onHide={() => {
                                            setShowMintedNftsModal(false)
                                        }}
                                        mintedNfts={mintedNfts.data}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}

export default Nft
