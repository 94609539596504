import React from "react";
import NftGroupCard from "../Components/NftGroupCard";
import Slider from "react-slick"
import SliderOneCol from "../Settings/SliderOneCol";
import SliderForBanner from "../Settings/SliderForBanner";
import {Link} from "react-router-dom";
import {useBanners, useNewlyReleasedNftGroups, usePopularSeries} from "../APIs/apis";
import Loading from "../Components/Loading";


const Home = () => {
    const newlyReleasedNftGroups = useNewlyReleasedNftGroups()
    const popularSeries = usePopularSeries()
    const banners = useBanners()

    const sliderForBanner = {
        ...SliderForBanner(),
        dots: true,
    }

    return (
        <div>
            <section className="py-0">
                <div className="container my-4">
                    <div className="mt-4 mb-3">
                        {
                            banners.isLoading ? <Loading/> : (banners.isSuccess && banners.data.length !== 0) &&
                                <div className={"mb-5"}>
                                    <Slider {...sliderForBanner}>
                                        {banners.data.map((banner, index) => {
                                            return (
                                                <div key={index}>
                                                    <div
                                                        className={"banner-img-frame border border-white rounded position-relative"}>
                                                        <img alt={"banner"} src={banner.banner_image_url}
                                                             className={"img-fluid w-100 d-md-none"}/>
                                                        <img alt={"banner"} src={banner.banner_desktop_image_url}
                                                             className={"img-fluid w-100 d-none d-md-block"}/>
                                                        <a href={banner.banner_link}
                                                           className={"stretched-link"}> </a>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </Slider>
                                </div>
                        }
                        <div className="d-flex align-items-center justify-content-between mb-2 pb-1">
                            <div className="h4 m-0 fw-bold">首發</div>
                            <div>
                                <Link to={"/explore"} className={"btn btn-outline-primary btn-sm px-3 d-none"}>View
                                    All</Link>
                            </div>
                        </div>
                        {
                            newlyReleasedNftGroups.isLoading ? <Loading/> : newlyReleasedNftGroups.isSuccess &&
                                <Slider {...SliderOneCol()}>
                                    {
                                        newlyReleasedNftGroups.data.map(function (nftGroup, index) {
                                            return (
                                                <NftGroupCard nftGroup={nftGroup} key={index} isSliderItem={true}/>
                                            )
                                        })}
                                </Slider>
                        }
                        <div className="d-flex align-items-center justify-content-between mb-2 pb-1 mt-4">
                            <div className="h4 m-0 fw-bold">熱門戲劇</div>
                            <div>
                                <Link to={"/series"} className={"btn btn-outline-primary btn-sm px-3"}>view all</Link>
                            </div>
                        </div>
                        {
                            popularSeries.isLoading ? <Loading/> : popularSeries.isSuccess &&
                                <Slider {...SliderOneCol()}>
                                    {popularSeries.data.map(function (singleSeries, index) {
                                        return (
                                            <div className="card bg-transparent border-0 mb-2"
                                                 key={index}>
                                                <div className={"series-card-img-frame rounded"}>
                                                    <img className="img-fluid w-100"
                                                         src={singleSeries.series_image_url}
                                                         alt={singleSeries.series_name}/>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="fw-bold">{singleSeries.series_name}</span>
                                                </div>
                                                <Link to={`single-series?id=${singleSeries.id}`}
                                                      className={"stretched-link"}></Link>
                                            </div>
                                        )
                                    })}
                                </Slider>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;