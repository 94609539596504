import React from "react";
import EmailInput from "./EmailInput";
import OneDreamButton from "./OneDreamButton";

export default function SendVerificationViaEmail({
                                                     title,
                                                     inputDescription,
                                                     email,
                                                     setEmail,
                                                     handleSendEmailButtonClick,
                                                     isEmailInputValid,
                                                     shouldCountDown,
                                                     setShouldCountDown,
                                                     count,
                                                     setCount,
                                                     isSendButtonEnabled,
                                                     isSendButtonLoading
                                                 }) {

    const getButtonText = () => {
        if (shouldCountDown) {
            return `重新發送 (${count}s)`
        }
        return "發送驗證碼"
    }

    React.useEffect(() => {
        let timerId;
        if (shouldCountDown) {
            timerId = setInterval(() => {
                setCount(count => count - 1)
            }, 1000)
        }

        if (count === 0) {
            setShouldCountDown(false)
            clearInterval(timerId)
        }

        return () => clearInterval(timerId)
    }, [shouldCountDown, setShouldCountDown, count, setCount])

    return (
        <>
            <p>{title}</p>
            <p className={"mt-4"}>{inputDescription}</p>
            <form>
                <EmailInput id={"email"} name={"email"} value={email} handleChange={setEmail}
                            isEmailInputValid={isEmailInputValid}/>
                <OneDreamButton enabled={isSendButtonEnabled()} text={getButtonText()}
                                onClick={handleSendEmailButtonClick}
                                className={"mt-4"}
                                isLoading={isSendButtonLoading}
                />
            </form>
        </>
    )
}