import {Link, useSearchParams} from "react-router-dom";
import {useNft} from "../APIs/apis";

export default function Purchased() {
    const [searchParams] = useSearchParams();
    const nftId = searchParams.get("nft_id")
    const purchasedPrice = searchParams.get('purchased_price')
    const nft = useNft(nftId, {
        enabled: !!nftId
    })

    return (
        <section className="mt-4">
            <div className="container text-center" style={{width: "300px"}}>
                <div className={"card border-0"}>
                    <h6 className="mb-2">成功支付</h6>
                    <h6 className="fw-bold mb-3">{parseFloat(purchasedPrice)}</h6>
                    <div>
                        <img className="rounded img-fluid" alt={nft?.data?.nft_name}
                             src={nft?.data?.nft_image_url}/>
                    </div>

                    <h5 className="mb-2 mt-3">您已成功購買</h5>

                    <h4><span className="fw-bold">{nft?.data?.nft_name}</span></h4>
                    <div>
                        <Link to={'/wallet'} className={"mt-2 btn btn-dark form-control"}>查看收藏項目</Link>
                    </div>

                </div>
            </div>
        </section>
    )
}
