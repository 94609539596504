import React, {useEffect, useState} from "react";
import {isValidEmailPattern} from "../Helpers";

export default function EmailInput({
                                       id,
                                       name,
                                       handleChange,
                                       value,
                                       setEmailInputFormatValid = null,
                                       handleOnBlur = null,
                                       isEmailInputValid = true,
                                   }) {
    const [borderColor, setBorderColor] = useState('lightgray')

    useEffect(() => {
        if (!isEmailInputValid) {
            setBorderColor('red')
            return
        }

        if (value.length !== 0 && !isValidEmailPattern(value)) {
            setBorderColor('red')
        } else if (value.length === 0) {
            setBorderColor('lightgray')
        } else {
            setBorderColor('black')
        }
    }, [value, isEmailInputValid, setBorderColor])

    useEffect(() => {
        if (setEmailInputFormatValid === null) {
            return
        }

        if (borderColor === 'black') {
            setEmailInputFormatValid(true)
            return
        }

        setEmailInputFormatValid(false)
    }, [borderColor, setEmailInputFormatValid])

    return (
        <input id={id}
               name={name}
               className={"form-control"}
               onChange={handleChange}
               onBlur={handleOnBlur}
               placeholder={"email@example.com"}
               value={value}
               style={{borderColor: borderColor}}
               autoComplete={"email"}
        />
    )
}