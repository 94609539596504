import {Modal, ModalBody} from "react-bootstrap";
import {Button} from "react-bootstrap";

export default function MetamaskInstallModal({show, onHide}) {
    return (
        <>
            <Modal show={show} onHide={() => onHide()} contentClassName={"rounded-modal-border"}>
                <Modal.Header closeButton className={"border-bottom-0"}>
                    <Modal.Title>您尚未安裝 MetaMask 錢包</Modal.Title>
                </Modal.Header>
                <ModalBody>
                    請前往 MetaMask <a href={"https://metamask.io/download/"} target={"_blank"} rel={"noreferrer"}>官方網站</a> 安裝錢包

                </ModalBody>
                <Modal.Footer className={"border-top-0"}>
                    <Button variant="secondary" onClick={() => onHide()}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
