import getAxios from "./axios";
import {useQuery, useInfiniteQuery, useMutation} from "@tanstack/react-query";
import queryString from 'query-string'
import {useRecoilState} from "recoil";
import authAtom from "../States/auth"
import useAxiosPrivate from "./useAxiosPrivate";
import {useNavigate} from "react-router-dom";
import loginModalAtom from "../States/loginModal";
import metamaskInstalledAtom from "../States/metamaskInstalled";
import {useLocation} from "react-router-dom";
import {ethers} from "ethers";
import {getMetamaskDAppDeepLink, getNetworkInfo, getChainIdPerEnv} from "../Helpers";
import {isMobile, isDesktop} from "react-device-detect";
import gettingStartedGuideAtom from "../States/gettingStartedGuide";

const axios = getAxios()
const precognitiveAxios = getAxios({"precognition": "true"})

export function useResetPassword() {
    const privateAxios = useAxiosPrivate()
    return useMutation(async (form) => {
        const data = {
            password: form.password,
            new_password: form.new_password
        }
        const res = await privateAxios.post('one-dream/user/reset-password', data)
        return res.data.data
    });
}

export function useResetForgottenPassword() {
    return useMutation(async (form) => {
        const data = {
            email: form.email,
            password: form.password,
            verification_code: form.verification_code
        }
        const res = await axios.post('one-dream/user/reset-forgotten-password', data)
        return res.data.data
    })
}

export function useSendUserForgetPasswordVerificationCode() {
    return useMutation(async (email) => {
        const res = await axios.post('one-dream/forget-password-verification-code/send', {email})
        return res.data.data
    })
}

export function usePrecognitionCheckEmailLoginInput(input) {
    return useMutation(async (form) => {
        const res = await precognitiveAxios.post('one-dream/user/login', form, {
            headers: {
                'precognition-validate-only': input
            }
        })
        return res.data.data
    })
}

export function useLoginWithEmail(options = {}, config = {}) {
    const [, setAuth] = useRecoilState(authAtom)
    return useMutation(async (form) => {
        const res = await axios.post('one-dream/user/login', form)
        return res.data.data
    }, {
        onSuccess: (data) => {
            const accessToken = data.access_token

            setAuth((preState) => {
                return {
                    ...preState,
                    accessToken: accessToken
                }
            })

            localStorage.setItem('isLogin', true)

            window.gtag('event', 'login', {
                method: 'Email'
            })
        },
        ...options
    })
}


export function useRegisterUser(options = {}, config = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation(async (form) => {
        const res = await axiosPrivate.post('one-dream/register', form, {...config})
        return res.data.data
    }, {...options})
}

export function useSendUserRegistrationVerificationCode(options = {}, config = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation(async (email) => {
        const res = await axiosPrivate.post('one-dream/register-verification-code/send', {email}, {...config})
        return res.data.data
    }, {...options})
}

export function useNfts(nftGroupIds = [], isOnSale = null, options = {}) {
    return useQuery(['nfts', 'nft-group-ids', ...nftGroupIds, 'is-on-sale', isOnSale], async () => {
        let queryObject = {nft_group_ids: nftGroupIds}
        if (isOnSale !== null) {
            queryObject.is_on_sale = isOnSale
        }

        const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})
        const res = await axios.get(`/one-dream/nfts?${query}`)
        return res.data.data
    }, {
        ...options
    })
}

export function useMyNfts(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useQuery(['nfts', 'mine'], async () => {
        const res = await axiosPrivate.get('one-dream/nfts/mine')
        return res.data.data;
    }, {
        ...options
    })
}

export function useSingleNftGroup(nftGroupIds, options = {}) {
    return useQuery(['nft-group', nftGroupIds], async () => {
        const res = await axios.get(`/one-dream/nft-groups/${nftGroupIds}`)
        return res.data.data
    }, {
        ...options
    })
}

export function useNftGroups(options = {}, seriesIds = [], actorIds = [], rows = 10, sort = '', sortDirection = '', isOnSale = '') {
    return useQuery([
        'nft-groups', 'series', ...seriesIds, 'actors', ...actorIds, 'rows', rows, 'sort', sort, 'sort-direction', sortDirection, 'is-on-sale', isOnSale
    ], async () => {
        const queryObject = {
            series_ids: seriesIds,
            actor_ids: actorIds,
            rows: rows,
            sort: sort,
            sort_direction: sortDirection,
            is_on_sale: isOnSale
        }

        const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})
        const res = await axios.get(`one-dream/nft-groups?${query}`)

        return res.data.data
    })
}

export function usePaginatedNftGroups(options = {}, seriesIds = [], actorIds = [], rows = 10, sort = '', sortDirection = '', isOnSale = '', search = '') {
    return useInfiniteQuery([
        'paginated-nft-groups', 'series', ...seriesIds, 'actors', ...actorIds, 'rows', rows, 'sort', sort, 'sort-direction', sortDirection, 'is-on-sale', isOnSale, 'search', search
    ], async ({pageParam = 0}) => {
        const queryObject = {
            series_ids: seriesIds,
            actor_ids: actorIds,
            rows: rows,
            sort: sort,
            sort_direction: sortDirection,
            is_on_sale: isOnSale,
            page: pageParam,
            search: search
        }

        const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})
        const res = await axios.get(`one-dream/nft-groups?${query}`)

        return res.data

    }, {
        ...options,
        getNextPageParam: (lastPage) => {
            const currentPage = lastPage.meta.current_page
            const nextPage = currentPage + 1
            const finalPage = lastPage.meta.last_page
            return nextPage > finalPage ? null : nextPage
        },
    })
}

export function usePaginatedNftTrxRecords(nftGroupIds = [], rows = 5, options = {}) {
    return useInfiniteQuery(['paginated-nft-trx-records', ...nftGroupIds, rows], async ({pageParam = 0}) => {
        const queryObject = {
            page: pageParam,
            rows: rows,
            nft_group_ids: nftGroupIds
        }

        const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})
        const res = await axios.get(`/one-dream/nft-transaction-records?${query}`)
        return res.data

    }, {
        getNextPageParam: (lastPage) => {
            const currentPage = lastPage.meta.current_page
            const nextPage = currentPage + 1
            const finalPage = lastPage.meta.last_page
            return nextPage > finalPage ? null : nextPage
        },
        ...options
    })
}

export function usePaginatedUserNotifications(options = {}) {
    const privateAxios = useAxiosPrivate()
    return useInfiniteQuery(['paginated-user-notifications'], async ({pageParam = 1}) => {
        const queryObject = {
            page: pageParam,
        }

        const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})
        const res = await privateAxios.get(`one-dream/user-notifications?${query}`)
        return res.data

    }, {
        getNextPageParam: (lastPage) => {
            const currentPage = lastPage.meta.current_page
            const nextPage = currentPage + 1
            const finalPage = lastPage.meta.last_page
            return nextPage > finalPage ? null : nextPage
        },
        ...options
    })
}

export function useNewlyReleasedNftGroups(options = {}) {
    return useQuery(['newly-released-nft-groups'], async () => {
        const res = await axios.get('/one-dream/newly-released-nft-groups')
        return res.data.data
    }, {
        ...options
    })
}

export function usePopularSeries(options = {}) {
    return useQuery(['popular-series'], async () => {
        const res = await axios.get('/one-dream/popular-series')
        return res.data.data
    }, {
        ...options
    })
}

export function useActors(seriesIds = [], options = {}) {
    const queryObject = {
        series_ids: seriesIds,
    }

    const query = queryString.stringify(queryObject, {arrayFormat: 'bracket'})

    return useQuery(['actors', 'series-ids', ...seriesIds], async () => {
        const res = await axios.get(`/one-dream/actors?${query}`)
        return res.data.data
    }, {
        ...options
    })
}

export function useActor(actorId, options = {}) {
    return useQuery(['actor', actorId], async () => {
        const res = await axios.get(`/one-dream/actors/${actorId}`)
        return res.data.data
    }, {
        ...options
    })
}

export function useSeries(options = {}) {
    return useQuery(['series'], async () => {
        const res = await axios.get('/one-dream/series')
        return res.data.data
    }, {
        ...options
    })
}

export function useNft(nftId, options = {}) {
    return useQuery(['nft', nftId], async () => {
        const res = await axios.get(`/one-dream/nfts/${nftId}`)
        return res.data.data
    }, {
        ...options
    })
}

export function useSingleSeries(seriesId, options = {}) {
    return useQuery(['single-series', seriesId], async () => {
        const res = await axios.get(`one-dream/series/${seriesId}`)

        return res.data.data
    }, {
        ...options
    })
}

export function useReferralCode(referralCode, options = {}) {
    const privateAxios = useAxiosPrivate()
    return useMutation({
        mutationFn: async (referralCode) => {
            return privateAxios.post('one-dream/user-referral-code', {
                referral_code: referralCode
            })
        }, onSuccess: (data, variables, context) => {
            window.gtag('event', 'referral-code-entered')
        }, ...options
    })
}

export function useLoginWithMetaMask() {
    const [, setAuth] = useRecoilState(authAtom)
    const [, setMetamaskInstalled] = useRecoilState(metamaskInstalledAtom)
    const [, setShowLoginModal] = useRecoilState(loginModalAtom)
    const location = useLocation()
    return useMutation({
        mutationFn: async () => {
            if (typeof window.ethereum === 'undefined') {
                if (isMobile) {
                    window.location.replace(getMetamaskDAppDeepLink(location))
                    return;
                }

                setMetamaskInstalled(false)
                return;
            }

            let provider = new ethers.providers.Web3Provider(window.ethereum)

            const network = await provider.getNetwork()

            const properChainId = getChainIdPerEnv()
            if (network.chainId !== properChainId) {
                const chainIdInHex = `0x${properChainId.toString(16)}`
                try {
                    await provider.send("wallet_switchEthereumChain", [{chainId: chainIdInHex}]);
                } catch (switchError) {
                    if ((isDesktop && switchError.code === 4902) || (isMobile && switchError.code === -32603)) {
                        try {
                            await provider.send('wallet_addEthereumChain',
                                [
                                    getNetworkInfo(properChainId)
                                ])

                        } catch (err) {
                            console.error(err.Error)
                            throw err
                        }
                    } else {
                        throw switchError
                    }
                }
            }

            provider = new ethers.providers.Web3Provider(window.ethereum)

            await provider.send("eth_requestAccounts", []);

            const signer = await provider.getSigner()

            const address = await signer.getAddress()

            const message = await getLoginMessage(address)

            const signature = await signer.signMessage(message)

            const res = await axios.post('one-dream/user/login', {
                signature: signature,
                address: address
            })

            return res.data.data

        }, onSuccess: (data, variables, context) => {
            const accessToken = data.access_token
            const initLogin = data.init_login

            setShowLoginModal(false)

            setAuth((preState) => {
                return {
                    ...preState,
                    accessToken: accessToken
                }
            })

            localStorage.setItem('isLogin', true)

            window.gtag('event', 'login', {
                method: 'MetaMask'
            })

            if (initLogin) {
                window.gtag('event', 'registration', {
                    method: 'MetaMask'
                })
            }

            // 登入後是否要導向登入前頁面
            // const from = location.state?.from?.pathname + location.state?.from?.search;
            // setShowLoginModal(false)
            //
            // if (from) {
            //     navigate(from)
            // }
        }
    })
}

export async function getLoginMessage(address) {
    const res = await axios.get('one-dream/to-be-signed-message', {
        params: {
            address: address
        }
    })
    return res.data.data.to_be_signed_message
}

export function useClaimNft(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            const res = await axiosPrivate.post('one-dream/nfts/claim')
            return res.data.data
        }, onSuccess: (data, variables, context) => {
            window.gtag('event', 'nft-claimed')
        }, ...options
    })
}

export function useRefreshToken() {
    const [, setAuth] = useRecoilState(authAtom)
    return async () => {
        try {
            const res = await axios.get('one-dream/user/refresh-token')
            const accessToken = res?.data?.data?.access_token
            setAuth(prev => {
                return {
                    ...prev,
                    accessToken: accessToken
                }
            })

            return accessToken
        } catch (err) {
            localStorage.removeItem('isLogin')
        }
    }
}

export function useUserSelf(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    const [, setShowGettingStartedGuide] = useRecoilState(gettingStartedGuideAtom)
    return useQuery(['user-self'], async () => {
        const res = await axiosPrivate.get('one-dream/user/myself')
        return res.data.data;
    }, {
        onSuccess: (data) => {
            if (!data.was_getting_started_guide_displayed) {
                setShowGettingStartedGuide(true)
            }
        },
        ...options
    })
}

export function useLazyMintData(nftId, options) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            const lazyMintData = await axiosPrivate.get('one-dream/lazy-mint-data/' + nftId)
            return lazyMintData.data.data
        }, ...options
    })
}

export function useRefreshBalances(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.patch('one-dream/balances')
        },
        ...options
    })
}

export function useLogout(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    const [, setAuth] = useRecoilState(authAtom)
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.get('one-dream/user/logout')
        }, onSuccess: () => {
            setAuth({})
            localStorage.removeItem('isLogin')
            window.location.replace('/')
        }, ...options
    })
}

export function useSyncNftOwnerOnChain(nftId) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation(async () => {
        await axiosPrivate.post(`one-dream/nfts/sync-owner-on-chain/${nftId}`)
    })
}

export function usePurchaseNft(nftId, nft) {
    const lazyMintData = useLazyMintData(nftId)
    const navigate = useNavigate()
    const [, setMetamaskInstalled] = useRecoilState(metamaskInstalledAtom)
    const syncNftOwnerOnChain = useSyncNftOwnerOnChain(nftId)
    return useMutation(async () => {
        if (typeof window.ethereum === 'undefined') {
            setMetamaskInstalled(false)
            return;
        }

        let provider = new ethers.providers.Web3Provider(window.ethereum)

        const network = await provider.getNetwork()

        if (network.chainId !== nft.data.chain_id) {
            const chainIdInHex = `0x${nft.data.chain_id.toString(16)}`
            try {
                await provider.send("wallet_switchEthereumChain", [{chainId: chainIdInHex}]);
            } catch (switchError) {
                if ((isDesktop && switchError.code === 4902) || (isMobile && switchError.code === -32603)) {
                    try {
                        await provider.send('wallet_addEthereumChain',
                            [
                                getNetworkInfo(nft.data.chain_id)
                            ])

                    } catch (err) {
                        console.error(err.Error)
                        throw err
                    }
                } else {
                    throw switchError
                }
            }
        }

        provider = new ethers.providers.Web3Provider(window.ethereum)

        const data = await lazyMintData.mutateAsync()

        await provider.send("eth_requestAccounts", []);

        const signer = await provider.getSigner()

        const connectedContract = new ethers.Contract(
            nft.data.smart_contract_address,
            data.abi,
            signer
        )

        let trxRes;

        try {
            trxRes = await connectedContract.lazyMint(data.voucher, {
                value: ethers.utils.parseEther(nft.data.nft_price)
            })
        } catch (err) {
            console.error(err)
            throw err
        }

        await trxRes.wait(2)

        await syncNftOwnerOnChain.mutateAsync()
    }, {
        onSuccess: () => {
            navigate(`/purchased?nft_id=${nftId}&purchased_price=${parseFloat(nft.data.nft_price)}`)
        }
    })
}

export function useTradeMarketTrxRecords(options = {}) {
    return useQuery(['trade-market-trx-records'], async () => {
        const res = await axios.get('one-dream/nft-transaction-trade-market-records')
        return res.data.data
    }, {
        ...options
    })
}

export function useBanners(options = {}) {
    return useQuery(['banners'], async () => {
        const res = await axios.get('one-dream/banners')
        return res.data.data
    }, {
        ...options
    })
}

export function useCheckInPromotionInfo(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useQuery(['check-in-info'], async () => {
        const res = await axiosPrivate.get('one-dream/check-in-info')
        return res.data.data
    }, {
        ...options
    })
}

export function useCheckIn(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.post('one-dream/check-in')
        }, ...options
    })
}

export function useClaimCheckInReward(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.post('one-dream/claim-check-in-reward')
        }, ...options
    })
}

export function useMarkAllNotificationsAsRead(options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.post(`one-dream/user-notifications/mark-all-as-read`)
        }, ...options
    })
}

export function useMarkNotificationAsRead(options = {}, notificationId) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.post(`one-dream/user-notifications/${notificationId}/mark-as-read`)
        }, ...options
    })
}

export function useUpdateUser(data = {}, options = {}) {
    const axiosPrivate = useAxiosPrivate()
    return useMutation({
        mutationFn: async () => {
            await axiosPrivate.patch(`one-dream/user/myself`, data)
        }, ...options
    })
}
