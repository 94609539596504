import {Link} from "react-router-dom";

export default function About() {
    return (
        <div>
            <div className={"one-dream-footer-title mb-2"}>關於 OneDream</div>
            <div role={"button"} className={"one-dream-footer-text position-relative"}>
                <Link to={"/organisation-intro"} className={"stretched-link"}/>
                公司簡介
            </div>
        </div>
    )
}