import React from "react";

const ConnectWarning = () => {
    return (
        <div className="modal fade" id="connectWarning" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1"
             aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content border-0 rounded shadow">
                    <div className="modal-header border-0">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div>
                            <h6>您尚未連接錢包, 請先完成錢包連接後繼續完成購買</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ConnectWarning